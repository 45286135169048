import axios from "axios";
import Utils from "../utils/config";

const req = axios.create();
const authInterceptor = config => {
  const auth = JSON.parse(localStorage.getItem('user'));
  if (auth) {
    const { access_token } = auth;
    config.headers['Authorization'] = `Bearer ${access_token}`;
  }
  return config;
};
req.interceptors.request.use(authInterceptor);

const API = Utils.API;

export const GET = (these, get, params, { noRequireToken } = { noRequireToken: false }) => {

  let auth = JSON.parse(localStorage.getItem('user'));
  let access_token = auth.access_token;
  console.log({ params })
  // if (!access_token && !noRequireToken) {
  //   return;
  // }

  axios.get(API + get, {
    params: params, headers: { Authorization: "Bearer " + access_token }
  }).then((response) => {

    let getParams = get.replace("-", "");
    let newState = getParams.split("/");
    these.setState({
      isLoaded: true,
      [newState[0]]: response.data.data || response.data,
    });
  }).catch(function (error) {
    console.error(error);
    // I don't know why anyone would think this is a good idea
    // localStorage.clear(); 
    // window.location.replace("/");
  });
};
export const POST = (these, post, data, message) => {

  let auth = JSON.parse(localStorage.getItem('user'));
  let access_token = auth.access_token;
  // if (!access_token && !noRequireToken) {
  //   return;
  // }
  axios.post(API + post, data, { headers: { Authorization: "Bearer " + access_token } }).then((response) => {
    these.setState({
      alertShow: true,
      alertMessage: message,
      alertType: "success",
    });
    GET(these, post);
  });
};
export const PUT = (these, post, urlGet, data, message) => {

  let auth = JSON.parse(localStorage.getItem('user'));
  let access_token = auth.access_token;
  axios.put(API + post, data, { headers: { Authorization: "Bearer " + access_token } }).then((response) => {
    if (message !== undefined) {
      these.setState({
        alertShow: true,
        alertMessage: message,
        alertType: "success",
      });
    }
    if (urlGet !== undefined) {
      GET(these, urlGet);
    }
  });
};

export { req, authInterceptor };
