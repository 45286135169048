import { Modal } from 'react-bootstrap';

export function PollSavedDialog({ encuestaNombre, nombre, codigo, encuestaId, show, onHide, publicPollType, linkPolls, setLinkPolls }) {
  const onClose = () => {
    if (linkPolls.length > 1) {

      // this is for display the next link poll
      setLinkPolls(linkPolls => {
        const newLinkPolls = [...linkPolls];
        // remove the first one (that is the current one) from the array
        newLinkPolls.shift();
        return newLinkPolls;
      })
    }
    onHide()
  }
  return (
    <Modal centered size='lg' show={show} onHide={onClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p className='text-center fw-bold'>
          La encuesta {encuestaNombre} ha sido guardada con éxito
        </p>
        <p className='text-center'>Nombre y apellido: {nombre}</p>
        <p className='text-center'>Código único: {codigo}</p>
        <p className='text-center'>Encuesta No. {encuestaId}</p>
        {publicPollType && linkPolls.length > 1 && <p className='text-center fw-bold'>
          Tienes mas encuestas pendientes por llenar ({linkPolls.length - 1}), porfavor completala(s)
        </p>}
        <button className='buttonSubmit' type='button' onClick={() => onClose()}>
          Continuar
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default PollSavedDialog;
