import React, { Component, createRef } from "react";
import { Button, Col, Row, Container, Nav, Form } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MaterialTable, { MTableToolbar } from "material-table";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import RowOptions from "../components/RowOptions";
import { req } from "../utils/request.js";
import { ExportToCsv } from "export-to-csv";
import FS from "../components/FieldStructure";
import { withRouter } from "react-router-dom";
import Alerts from "../common/Alerts";
import Audits from "../components/Audits";
import $ from "jquery";
import { API_URL } from "../utils/config";
import HistoryIcon from "@mui/icons-material/History";
import answerService from "../services/answers";
import { ROLE_SYSTEM_ADMIN } from "../utils/roles";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";

class FormFieldCreateEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questiontypes: [],
      forms: [],
      formfields: [],
      dependentfields: [],
      formfieldsAll: [],
      sections: [],
      sectionsFiltered: [],
      fieldresponses: [],
      fieldresponsesSelected: [],
      matrizQuestions: [],
      matrizAnswers: [],
      fieldresponsesForDependence: [],
      tableData: [],
      selectableQuestions: [],
      dependentfieldsSelected: [],
      isEditing: false,
      alertShow: false,
      alertMessage: "",
      alertType: "",
      dependence_type: "",
      auditType: "dependent_field",
      auditID: 0,
      showAudit: false,
      formCreation: [],
    };
    this.answer = React.createRef();
    this.peso = React.createRef();
    this.answerSubmit = React.createRef();
    this.addAnswer = this.addAnswer.bind(this);
    this.matrizQuestion = React.createRef();
    this.matrizAnswers = React.createRef();
    this.addSubQuestion = this.addSubQuestion.bind(this);
    this.addSubAnswer = this.addSubAnswer.bind(this);
    this.matrizQuestionSubmit = React.createRef();
    this.matrizAnswersSubmit = React.createRef();

    this.tableRef = createRef();
    this.tableRefSubQ = createRef();
    this.tableRefSubA = createRef();

    this.dependence_formfield = React.createRef();
    this.dependence_operator = React.createRef();
    this.dependence_response = React.createRef();
    this.addDependenceSubmit = React.createRef();
    this.action_RowOptions = this.action_RowOptions.bind(this);
    this.action_RowOptionsDependence =
      this.action_RowOptionsDependence.bind(this);
    this.formSelected = this.formSelected.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }

  showAudit(data) {
    this.setState({
      auditID: data._id,
      showAudit: true,
    });
  }
  closeAudit() {
    this.setState({
      showAudit: false,
    });
  }
  componentDidMount() {
    const auth = JSON.parse(localStorage.getItem("user"));
    if (auth === undefined || auth.user?.role?.nombre !== ROLE_SYSTEM_ADMIN) {
      this.props.history.push("/");
    }
    const fieldId = this.props.match.params.questionId;
    this.setState({
      formCreation: JSON.parse(sessionStorage.getItem("formCreation")),
    });

    if (fieldId !== undefined) {
      req
        .get(`${API_URL}formularios/items/${fieldId}/show`)
        .then((response) => {
          const formId = this.state.formCreation;
          req
            .get(`${API_URL}formularios/secciones`, {
              params: {
                formulario: formId,
                paginacion: 999999,
              },
            })
            .then((sect) => {
              this.setState({
                formfields: response.data.data,
                sectionsFiltered: sect.data.data,
              });
            });

          // update questions field
          req
            .get(`${API_URL}formularios/items`, {
              params: {
                formulario: formId,
                estado: "activos",
                paginacion: 999999,
              },
            })
            .then((response) => {
              const allQuestions = response.data.data ?? [];
              this.setState({
                isLoaded: true,
                formfieldsAll: allQuestions,
                selectableQuestions: allQuestions.filter(
                  (elem) => elem.id !== this.props.match.params.fieldId
                ),
              });
            });

          const matrizQuestionsFormat = response.data.data?.sub_pregunta
            ? response.data.data.sub_pregunta
              .split(' | ') // Divide la cadena en un array de respuestas
              .map(respuesta => ({ respuesta, activo: true })) // Mapea cada respuesta a un objeto
            : []; // Si `sub_respuesta` no existe o es `null`, devuelve un array vacío 

          this.setState({
            isEditing: true,
            dependentfields: response.data.data.dependencias,
            matrizQuestions: matrizQuestionsFormat,
            dependentfieldsSelected: response.data.data.dependencias.map(
              ({
                id,
                formulario_item_id,
                formulario_item_id_dependencia,
                operador,
                respuesta,
                valor,
                activo,
              }) => ({
                id,
                formulario_item_id,
                formulario_item_id_dependencia,
                operador,
                respuesta: respuesta?.id,
                valor,
                activo,
              })
            ),
          });
        });

      answerService.query(fieldId, 999999).then((response) => {
        const matrizAnswerFormated = [];

        // Validamos el filtro sobre response.data.data (siempre debe ser un array)
        const fieldresponsesSelected = (response?.data?.data ?? []).filter(
          (elem) => elem.activo
        );

        this.setState({
          fieldresponsesSelected
        });
      });

    } else {
      let formCreation = JSON.parse(sessionStorage.getItem("formCreation"));
      req
        .get(`${API_URL}formularios/items`, {
          params: {
            formulario: formCreation,
            estado: "activos",
            paginacion: 9999,
          },
        })
        .then((response) => {
          const allQuestions = response.data.data ?? [];
          this.setState({
            isLoaded: true,
            formfieldsAll: allQuestions,
            selectableQuestions: allQuestions.filter(
              (elem) => elem.id !== this.props.match.params.fieldId
            ),
          });
        });
    }

    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.encuesta .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.campos").addClass("active");
    });
    let formCreation = JSON.parse(sessionStorage.getItem("formCreation"));
    req
      .get(`${API_URL}tipos/pregunta`, {
        params: { estado: "activos", paginacion: 999999 },
      })
      .then((response) => {
        this.setState({
          questiontypes: response.data.data ?? [],
        });
      });
    req
      .get(`${API_URL}formularios/secciones`, {
        params: {
          formulario: formCreation,
          paginacion: 999999,
        },
      })
      .then((response) => {
        this.setState({
          sectionsFiltered: response.data.data ?? response.data,
        });
      });
    // req
    //   .get(`${API_URL}formularios`, {
    //     params: { estado: 'activos', paginacion: 999999 },
    //   })
    //   .then((response) => {
    //     this.setState({
    //       forms: response.data.data ?? [],
    //     });
    //   });

    // Why is there a timeout here?
    setTimeout(() => {
      this.setState({ show: true });
    }, 500);
  }
  action_RowOptions(action, data) {
    const rows = [...this.state.fieldresponsesSelected];
    const rowToEditIndex = rows.findIndex(
      (elem) => elem.id && elem.respuesta === data.data.respuesta
    );
    switch (action) {
      case "Desactivar":
        if (rowToEditIndex >= 0) {
          req
            .post(
              `${API_URL}formularios/respuestas/${rows[rowToEditIndex].id}/toggle`
            )
            .then(() => {
              rows[rowToEditIndex].activo = false;
              this.setState({ fieldresponsesSelected: rows });
            });
        }
        break;
      case "Activar":
        if (rowToEditIndex >= 0) {
          req
            .post(
              `${API_URL}formularios/respuestas/${rows[rowToEditIndex].id}/toggle`
            )
            .then(() => {
              rows[rowToEditIndex].activo = true;
              this.setState({ fieldresponsesSelected: rows });
            });
        }
        break;
      default:
        // do nothing
        break;
    }
  }
  action_RowOptionsDependence(action, data) {
    const rows = [...this.state.dependentfieldsSelected];
    const rowToEditIndex = rows.findIndex((elem) => {
      if (elem.id) {
        return elem.id === data.id;
      } else {
        return (
          elem.formulario_item_id_dependencia ===
          data.data.formulario_item_id_dependencia &&
          elem.respuesta === data.respuesta &&
          elem.operador === data.operador
        );
      }
    });
    switch (action) {
      case "Desactivar":
        if (rowToEditIndex >= 0) {
          req
            .post(
              `${API_URL}formularios/items/dependencias/${rows[rowToEditIndex].id}/toggle`
            )
            .then(() => {
              rows[rowToEditIndex].activo = false;
              this.setState({ dependentfieldsSelected: rows });
            });
        }
        break;
      case "Activar":
        if (rowToEditIndex >= 0) {
          req
            .post(
              `${API_URL}formularios/items/dependencias/${rows[rowToEditIndex].id}/toggle`
            )
            .then(() => {
              rows[rowToEditIndex].activo = true;
              this.setState({ dependentfieldsSelected: rows });
            });
        }
        break;
      default:
        break;
    }
  }
  addAnswer() {
    if (this.answer.current.value === "") {
      return false;
    }
    const answer = {
      respuesta: this.answer.current.value,
      peso: this.peso.current.value,
      activo: true,
    };
    this.setState((state) => ({
      fieldresponsesSelected: [...state.fieldresponsesSelected, answer],
    }));
    this.answer.current.value = "";
    this.peso.current.value = "";
    this.answer.current.focus();
    this.tableRef.current.onQueryChange({});
  }
  addSubQuestion() {
    if (this.matrizQuestion.current.value === "") {
      return false;
    }
    const question = {
      respuesta: this.matrizQuestion.current.value,
      activo: true,
    };
    this.setState((state) => ({
      matrizQuestions: [...state.matrizQuestions, question],
    }));
    this.matrizQuestion.current.value = "";
    this.matrizQuestion.current.focus();
    this.tableRefSubQ.current.onQueryChange({});
  }
  addSubAnswer() {
    if (this.matrizAnswers.current.value === "") {
      return false;
    }
    const question = {
      respuesta: this.matrizAnswers.current.value,
      activo: true,
    };
    this.setState((state) => ({
      matrizAnswers: {
        ...state.matrizAnswers,
        sub_respuesta: [...state.matrizAnswers.sub_respuesta, question]
      }
    }));

    this.matrizAnswers.current.value = "";
    this.matrizAnswers.current.focus();
    this.tableRefSubA.current.onQueryChange({});
  }
  renderAnswer(pregunta_id, respuesta_id) {
    const pregunta = this.state.formfieldsAll.find(
      (elem) => elem.id === pregunta_id
    ) ?? { tipo_pregunta: { nombre: "" } };
    const { tipo_pregunta } = pregunta;
    const answers = this.state.fieldresponses;
    if (
      tipo_pregunta.nombre.toLowerCase() === "selección múltiple" ||
      tipo_pregunta.nombre.toLowerCase() === "selección única"
    ) {
      return answers.find((elem) => elem.id === respuesta_id)?.respuesta;
    }
    if (tipo_pregunta.nombre.toLowerCase() === "si o no") {
      return respuesta_id === "1" ? "Si" : "No";
    }
    if (tipo_pregunta.nombre.toLowerCase() === "verdader o falso") {
      return respuesta_id === "1" ? "Verdadero" : "Falso";
    }
  }

  addDependence(formik) {
    const { values } = formik;
    if (values.dependence_formfield === "") {
      return false;
    }
    if (values.dependence_operator === "") {
      return false;
    }
    if (values.dependence_response === "") {
      return false;
    }

    this.setState({ dependence_type: "" });

    const question = this.state.formfieldsAll.find(
      (elem) => elem.id === parseInt(values.dependence_formfield)
    );
    const form_field_id = values.dependence_formfield;
    const operator = values.dependence_operator;
    const response_id =
      question.tipo_pregunta.nombre.toLowerCase() === "selección única" ||
        question.tipo_pregunta.nombre.toLowerCase() === "selección múltiple"
        ? values.dependence_response
        : "";
    const valor = this.getValor(
      question.tipo_pregunta.tipo_dato,
      values.dependence_response
    );
    values.dependence_response = "";
    values.dependence_formfield = "";
    values.dependence_operator = "";

    
    this.setState((state) => {
      const result = {
        dependentfieldsSelected: [
          ...state.dependentfieldsSelected,
          {
            formulario_item_id: this.props.match.params.fieldId,
            formulario_item_id_dependencia: form_field_id,
            operador: operator,
            respuesta: response_id,
            valor: valor,
            activo: true,
          },
        ],
      };
      return result;
    });
  }
  selectDependence_formfield(value, formik) {
    formik.setFieldValue("dependence_formfield", value);
    answerService.query(value, 999999).then((response) => {
      this.setState({
        fieldresponses: response.data.data ?? [],
      });
    });
    this.setState((state) => {
      const selectedQuestion = state.formfieldsAll.find(
        (elem) => elem.id === parseInt(value)
      );
      return { dependence_type: selectedQuestion?.tipo_pregunta?.tipo_dato };
    });
  }
  formSelected(formik) {
    const formulario_id = formik.values.formulario_id;
    formik.setFieldValue("section_id", ""); // reset selected section
    req
      .get(`${API_URL}formularios/secciones`, {
        params: {
          formulario: formulario_id,
          estado: "activos",
          paginacion: 999999,
        },
      })
      .then((response) => {
        this.setState({
          sectionsFiltered: response.data.data ?? [],
        });
      });
    // update questions field
    req
      .get(`${API_URL}formularios/items`, {
        params: {
          formulario: formulario_id,
          estado: "activos",
          paginacion: 9999,
        },
      })
      .then((response) => {
        const allQuestions = response.data.data ?? [];
        this.setState({
          isLoaded: true,
          formfieldsAll: allQuestions,
          selectableQuestions: allQuestions.filter(
            (elem) => elem.id !== this.props.match.params.fieldId
          ),
        });
      });
  }
  getValor(tipo_pregunta, response_id) {
    switch (tipo_pregunta.toLowerCase()) {
      case "si o no":
        return response_id === "1" ? "Si" : "No";
      case "verdadero o falso":
        return response_id === "1" ? "Verdadero" : "Falso";
      case "unico":
      case "multiple": 
        // TODO find answer in list and return 'respuesta'
        return this.state.fieldresponses.find((elem) => elem.id === parseInt(response_id))
          ?.respuesta;
        default:
        return response_id; 
    }
  }

  renderAnswerField(formik) {
    const { fieldresponses, dependence_type } = this.state;
    switch (dependence_type.toLowerCase()) {
      case "unico":
      case "multiple":
        return (
          <Field
            ref={this.answerDependence}
            name="dependence_response"
            as="select"
          >
            <option value=""> Selecciona</option>
            {fieldresponses
              .filter(
                (respuesta) =>
                  respuesta.formulario_item_id ===
                  parseInt(formik.values.dependence_formfield) && respuesta.activo
              ) // TODO remove the filter call
              .map((respuesta) => (
                <option key={respuesta.id} value={respuesta.id}>
                  {" " + respuesta.respuesta + " "}
                </option>
              ))}
          </Field>
        );
      case "si o no":
        return (
          <Field
            ref={this.answerDependence}
            name="dependence_response"
            as="select"
          >
            <option value=""> Selecciona </option>
            <option value="1"> Si </option>
            <option value="0"> No </option>
          </Field>
        );
      case "verdadero o falso":
        return (
          <Field
            ref={this.answerDependence}
            name="dependence_response"
            as="select"
          >
            <option value=""> Selecciona </option>
            <option value="1"> Verdadero </option>
            <option value="0"> Falso </option>
          </Field>
        );

      case "": // no question selected
        return null;
      default:
        return (
          <Field
            ref={this.answerDependence}
            type="text"
            name="dependence_response"
          />
        );
    }
  }

  addFrequencyOptions() {
    const answers = [
      ...this.state.fieldresponsesSelected,
      { respuesta: "Nunca", activo: true, peso: 1 },
      { respuesta: "Ocasionalmente", activo: true, peso: 2 },
      { respuesta: "Rara vez", activo: true, peso: 3 },
      { respuesta: "Algo a menudo", activo: true, peso: 4 },
      { respuesta: "Siempre", activo: true, peso: 5 },
      { respuesta: "No responde", activo: true, peso: 6 },
    ];
    this.setState({ fieldresponsesSelected: [...answers] });
    this.tableRef.current.onQueryChange({});
  }

  addAgreementOptions() {
    const answers = [
      ...this.state.fieldresponsesSelected,
      { respuesta: "Totalmente en desacuerdo", activo: true, peso: 1 },
      { respuesta: "En desacuerdo", activo: true, peso: 2 },
      { respuesta: "Ni acuerdo ni en desacuerdo", activo: true, peso: 3 },
      { respuesta: "De acuerdo", activo: true, peso: 4 },
      { respuesta: "Totalmente de acuerdo", activo: true, peso: 5 },
      { respuesta: "No responde", activo: true, peso: 6 },
    ];
    this.setState({ fieldresponsesSelected: [...answers] });
    this.tableRef.current.onQueryChange({});
  }

  addMotivationOptions() {
    const answers = [
      ...this.state.fieldresponsesSelected,
      { respuesta: "No tengo ninguna motivación", activo: true, peso: 1 },
      { respuesta: "No estoy totalmente motivado/a", activo: true, peso: 2 },
      { respuesta: "Me da igual", activo: true, peso: 3 },
      { respuesta: "Me siento algo motivado/a", activo: true, peso: 4 },
      { respuesta: "Me siento totalmente motivado/a", activo: true, peso: 5 },
      { respuesta: "No responde", activo: true, peso: 6 },
    ];
    this.setState({ fieldresponsesSelected: [...answers] });
    this.tableRef.current.onQueryChange({});
  }

  add1To5() {
    const answers = this.state.fieldresponsesSelected;
    for (let i = 1; i <= 5; i++) {
      const answer = { respuesta: `${i}`, activo: true, peso: i };
      answers.push(answer);
    }
    answers.push({ respuesta: `No responde`, activo: true, peso: 6 });
    this.setState({ fieldresponsesSelected: [...answers] });
    this.tableRef.current.onQueryChange({});
  }

  renderPossibleAnswers(formik) {
    const { questiontypes, fieldresponsesSelected } = this.state;
    const uniqueAnswer = questiontypes.find(
      (elem) => elem.nombre.toLowerCase() === "selección única"
    );
    const multipleAnswer = questiontypes.find(
      (elem) => elem.nombre.toLowerCase() === "selección múltiple"
    );
    const matriz = questiontypes.find(
      (elem) => elem.nombre.toLowerCase() === "matriz"
    );
    const shouldHide = !(
      formik.values.question_type_id == uniqueAnswer?.id ||
      formik.values.question_type_id == multipleAnswer?.id
    );
    if (shouldHide) {
      return null;
    }

    const downloadCsv = () => {
      if (fieldresponsesSelected.length === 0) {
        return;
      }
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        title: "",
        filename: "Posibles Respuestas",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      const csvData = [];
      for (let elem of fieldresponsesSelected) {
        const row = { ...elem };
        delete row.tableData; // remove this property added by MaterialTable
        csvData.push(row);
      }
      csvExporter.generateCsv(csvData);
    };
    return (
      <Row>
        <Col lg={12}>
          <strong>Posibles Respuestas</strong>
          <div className="subBox">
            <div className="form-group">
              <Row>
                <Col lg={4}>
                  <label>Añadir Respuesta</label>
                  <input name="answer" ref={this.answer} type="text"></input>
                </Col>
                <Col lg={4}>
                  <label>Peso</label>
                  <input name="peso" ref={this.peso} type="text"></input>
                </Col>
                <Col lg={4}>
                  <br />
                  <Button
                    variant="primary "
                    ref={this.answerSubmit}
                    className="secondaryButton"
                    onClick={this.addAnswer}
                  >
                    Añadir
                  </Button>
                  {formik.values.question_type_id != matriz?.id ?
                    <>
                      <Button
                        variant="primary"
                        className="secondaryButton"
                        onClick={() => this.addMotivationOptions()}
                      >
                        Añadir escala de motivación
                      </Button>

                      <Button
                        variant="primary"
                        className="secondaryButton"
                        onClick={() => this.add1To5()}
                      >
                        Añadir escala del 1 al 5
                      </Button>

                      <Button
                        variant="primary"
                        className="secondaryButton"
                        onClick={() => this.addAgreementOptions()}
                      >
                        Añadir escala de acuerdo
                      </Button>

                      <Button
                        variant="primary"
                        className="secondaryButton"
                        onClick={() => this.addFrequencyOptions()}
                      >
                        Añadir escala de frecuencia
                      </Button>
                    </>
                    : null}
                </Col>
              </Row>
            </div>
            <MaterialTable
              tableRef={this.tableRef}
              title=""
              columns={[
                {
                  title: "Respuesta",
                  field: "respuesta",
                  editable: "onUpdate",
                },
                {
                  title: "Peso",
                  field: "peso",
                  editable: "onUpdate",
                },
                {
                  title: "Estatus",
                  field: "activo",
                  editable: "never",
                  render: (rowData) => (rowData.activo ? "Activo" : "Inactivo"),
                },
                {
                  title: "",
                  searchable: false,
                  field: "actions",
                  editable: "never",
                  render: (rowData) =>
                    rowData.activo ? (
                      <RowOptions
                        data={rowData}
                        parentCallback={this.action_RowOptions}
                        options={["Desactivar"]}
                      />
                    ) : (
                      <RowOptions
                        data={rowData}
                        parentCallback={this.action_RowOptions}
                        options={["Activar"]}
                      />
                    ),
                },
              ]}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />

                    <div style={{ position: 'absolute', top: 0, zIndex: 9 }} className="d-flex justify-content-end">
                      {fieldresponsesSelected.length > 0 && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={downloadCsv}
                          title="Descargar CSV"
                        >
                          <FileDownloadIcon />
                        </button>
                      )}
                    </div>
                  </div>
                ),
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "right",
                paging: true,
                pageSize: 10,
                draggable: false,
              }}
              data={(query) =>
                new Promise((resolve) => {
                  const { search, page, pageSize } = query;
                  const totalCount = fieldresponsesSelected.length;
                  const data = fieldresponsesSelected
                    .filter(
                      (respuesta) =>
                        respuesta.respuesta
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                    )
                    .slice(page * pageSize, pageSize * (page + 1));
                  resolve({
                    data,
                    page,
                    totalCount,
                  });
                })
              }

              localization={{
                header: {
                  actions: 'Acciones'
                }
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const answers = [...fieldresponsesSelected];
                    answers[oldData.tableData.id].respuesta = newData.respuesta;
                    answers[oldData.tableData.id].peso = newData.peso;
                    // 5. Set the state to our new copy
                    this.setState({
                      fieldresponsesSelected: answers,
                    });
                    resolve();

                    req.post(
                      `${API_URL}formularios/respuestas/${newData.id}/update`,
                      {
                        respuesta: newData.respuesta,
                        peso: newData.peso,
                        formulario_item: newData.formulario_item_id,
                      }
                    );
                  }),
              }}
            />
          </div>
        </Col>
      </Row>
    );
  }
  renderMatrizQuestions(formik, fieldId) {
    const { questiontypes, matrizQuestions } = this.state;
    const matrizQuestion = questiontypes.find(
      (elem) => elem.nombre.toLowerCase() === "matriz"
    );
    const shouldHide = !(
      formik.values.question_type_id == matrizQuestion?.id
    );
    if (shouldHide) {
      return null;
    }

    return (
      <Row>
        <Col lg={12}>
          <strong>Sub-Preguntas</strong>
          <div className="subBox">
            <div className="form-group">
              <Row>
                <Col lg={4}>
                  <label>Añadir Pregunta</label>
                  <input name="matrizQuestion" ref={this.matrizQuestion} type="text"></input>
                </Col>
                <Col lg={4}>
                  <br />
                  <Button
                    variant="primary "
                    ref={this.matrizQuestionSubmit}
                    className="secondaryButton"
                    onClick={this.addSubQuestion}
                  >
                    Añadir
                  </Button>

                </Col>
              </Row>
            </div>
            <MaterialTable
              tableRef={this.tableRefSubQ}
              title=""
              columns={[
                {
                  title: "Sub-Pregunta",
                  field: "respuesta",
                  editable: "onUpdate",
                  render: (rowData) => rowData.respuesta.replace(/_/g, " "),
                },
                {
                  title: "",
                  searchable: false,
                  field: "Acciones",
                  editable: "never",
                  render: (rowData) =>
                    rowData.activo ? (
                      <RowOptions
                        data={rowData}
                        parentCallback={this.action_RowOptions}
                        options={["Desactivar"]}
                      />
                    ) : (
                      <RowOptions
                        data={rowData}
                        parentCallback={this.action_RowOptions}
                        options={["Activar"]}
                      />
                    ),
                },
              ]}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: false,
                selection: false,
                searchFieldAlignment: "right",
                paging: false,
                pageSize: 20,
                draggable: false,
              }}
              data={(query) =>
                new Promise((resolve) => {
                  const { search, page, pageSize } = query;
                  const totalCount = matrizQuestions.length;
                  const data = matrizQuestions
                    .filter(
                      (respuesta) =>
                        respuesta.respuesta
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                    )
                    .slice(page * pageSize, pageSize * (page + 1));
                  resolve({
                    data,
                    page,
                    totalCount,
                  });
                })
              }

              localization={{
                header: {
                  actions: 'Acciones'
                }
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const answers = [...matrizQuestions];
                    answers[oldData.tableData.id].respuesta = newData.respuesta;
                    // 5. Set the state to our new copy
                    this.setState({
                      matrizQuestions: answers,
                    });
                    resolve();
                  }),
              }}
            />
          </div>
        </Col>
      </Row>
    );
  }
  renderMatrizAnswers(formik) {
    const { questiontypes, matrizAnswers } = this.state;
    const matrizAnswer = questiontypes.find(
      (elem) => elem.nombre.toLowerCase() === "matriz"
    );
    const shouldHide = !(
      formik.values.question_type_id == matrizAnswer?.id
    );
    if (shouldHide) {
      return null;
    }

    return (

      <Row>
        <Col lg={12}>
          <br />
          <strong>Respuestas</strong>
          <div className="subBox">
            <div className="form-group">
              <Row>
                <Col lg={4}>
                  <label>Añadir Respuesta</label>
                  <input name="matrizAnswers" ref={this.matrizAnswers} type="text"></input>
                </Col>
                <Col lg={4}>
                  <br />
                  <Button
                    variant="primary"
                    ref={this.matrizAnswersSubmit}
                    className="secondaryButton"
                    onClick={this.addSubAnswer}
                  >
                    Añadir
                  </Button>

                </Col>
              </Row>
            </div>
            <MaterialTable
              tableRef={this.tableRefSubA}
              title=""
              columns={[
                {
                  title: "Respuesta",
                  field: "respuesta",
                  editable: "onUpdate",
                  render: (rowData) => rowData.respuesta.replace(/_/g, " "),

                },
                {
                  title: "Estatus",
                  field: "activo",
                  editable: "never",
                  render: (rowData) => (rowData.activo ? "Activo" : "Inactivo"),
                },
                {
                  title: "",
                  searchable: false,
                  field: "actions",
                  editable: "never",
                  render: (rowData) =>
                    rowData.activo ? (
                      <RowOptions
                        data={rowData}
                        parentCallback={this.action_RowOptions}
                        options={["Desactivar"]}
                      />
                    ) : (
                      <RowOptions
                        data={rowData}
                        parentCallback={this.action_RowOptions}
                        options={["Activar"]}
                      />
                    ),
                },
              ]}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: false,
                selection: false,
                searchFieldAlignment: "right",
                paging: false,
                pageSize: 20,
                draggable: false,
              }}
              data={(query) =>
                new Promise((resolve) => {
                  const { search, page, pageSize } = query;
                  const totalCount = matrizAnswers.length;
                  const data = matrizAnswers.sub_respuesta
                    .filter(
                      (respuesta) =>
                        respuesta.respuesta
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                    )
                    .slice(page * pageSize, pageSize * (page + 1));
                  resolve({
                    data,
                    page,
                    totalCount,
                  });
                })
              }
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const answers = { ...matrizAnswers, sub_respuesta: [...matrizAnswers.sub_respuesta] };
                    answers.sub_respuesta[oldData.tableData.id].respuesta = newData.respuesta;
                    // 5. Set the state to our new copy
                    this.setState({
                      matrizAnswers: answers,
                    });
                    resolve();
                  })
              }}
            />
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    const {
      alertType,
      alertMessage,
      alertShow,
      questiontypes,
      formfields,
      formfieldsAll,
      fieldresponsesSelected,
      sectionsFiltered,
      formCreation,
      dependentfieldsSelected,
      matrizQuestions,
      matrizAnswers,
      isEditing,
    } = this.state;
    const validationSchema = Yup.object().shape({
      pregunta: Yup.string().required("La pregunta es un campo requerido."),
      seccion_id: Yup.string().required("La sección es un campo requerido."),
      question_type_id: Yup.string()
        .nullable()
        .required("El tipo de pregunta es un campo requerido."),
    });
    const fieldId = this.props.match.params.questionId;
    return (
      <Container>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.auditID}
          />
        )}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <Formik
              initialValues={{
                pregunta: formfields.pregunta || "",
                question_type_id: formfields.tipo_pregunta?.id || "",
                required: formfields.requerido || false,
                seccion_id: formfields.seccion?.id || "",
                orden: formfields.orden || 0,
                indice: formfields.indice ?? 0,
                dependence_response: "",
                dependence_formfield: "",
                dependence_operator: "",
                codificacion: formfields.codificacion || "",
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                values.activo = true;
                setSubmitting(true);

                if (isEditing) {
                  const formattedMatrizSubQuestion = matrizQuestions
                    .filter(item => item.activo) // Filtra solo los objetos donde `activo` es `true`
                    .map(item => item.respuesta.replace(/ /g, '_')) // Mapea solo el valor de `respuesta`
                    .join(' | '); // Une los valores con el separador `|`

                  const formattedMatrizSubAnswer = (matrizAnswers?.sub_respuesta || [])
                    .filter(item => item.activo) // Filtra solo los objetos donde `activo` es `true`
                    .map(item => item.respuesta.replace(/ /g, '_')) // Mapea solo el valor de `respuesta`
                    .join(' | '); // Une los valores con el separador `|`  
                  req
                    .post(`${API_URL}formularios/items/${fieldId}/update`, {
                      formulario_seccion: values.seccion_id,
                      tipo_pregunta: values.question_type_id,
                      orden: values.orden,
                      indice: values.indice,
                      pregunta: values.pregunta,
                      requerido: values.required ? 1 : 0,
                      codificacion: values.codificacion,
                      sub_pregunta: formattedMatrizSubQuestion
                    })
                    .then((response) => {
                      if (formattedMatrizSubAnswer) {
                        req.post(`${API_URL}formularios/respuestas/${matrizAnswers.id}/update`, {
                          respuesta: "N/A",
                          peso: 0,
                          sub_respuesta: formattedMatrizSubAnswer,
                          formulario_item: response.data.data.id,
                        });
                      } else {
                        fieldresponsesSelected.forEach((elem) => {
                          if (elem.id !== undefined) {
                            return;
                          } else {
                            req.post(`${API_URL}formularios/respuestas/store`, {
                              respuesta: elem.respuesta,
                              peso: elem.peso,
                              formulario_item: response.data.data.id,
                            });
                          }
                        });
                      }
                      dependentfieldsSelected.forEach((elem) => {
                        if (elem.id !== undefined) {
                          return;
                        } else {
                          req.post(
                            `${API_URL}formularios/items/dependencias/store`,
                            {
                              formulario_item: fieldId,
                              formulario_item_dependencia:
                                elem.formulario_item_id_dependencia,
                              formulario_respuesta: elem.respuesta,
                              operador: elem.operador,
                              valor: elem.valor,
                            }
                          );
                        }
                      });
                      $(window).scrollTop(0);
                      this.props.history.push({
                        pathname: "/formulario/" + formCreation,
                        state: {
                          alertShow: true,
                          alertMessage: "Pregunta Actualizada",
                          alertType: "success",
                        },
                      });
                    });
                } else {
                  const formattedMatrizSubQuestion = matrizQuestions
                    .filter(item => item.activo) // Filtra solo los objetos donde `activo` es `true`
                    .map(item => item.respuesta.replace(/ /g, '_')) // Mapea solo el valor de `respuesta`
                    .join(' | '); // Une los valores con el separador `|`

                  const formattedMatrizSubAnswer = matrizAnswers
                    .filter(item => item.activo) // Filtra solo los objetos donde `activo` es `true`
                    .map(item => item.respuesta.replace(/ /g, '_')) // Mapea solo el valor de `respuesta`
                    .join(' | '); // Une los valores con el separador `|`   
                  req
                    .post(`${API_URL}formularios/items/store`, {
                      formulario_seccion: values.seccion_id,
                      tipo_pregunta: values.question_type_id,
                      orden: values.orden,
                      indice: values.indice,
                      pregunta: values.pregunta,
                      requerido: values.required ? 1 : 0,
                      codificacion: values.codificacion,
                      sub_pregunta: formattedMatrizSubQuestion
                    })
                    .then((response) => {
                      const question_id = response.data.data.id;

                      // save conditions to show this question, if any
                      dependentfieldsSelected.forEach(
                        ({
                          formulario_item_id_dependencia,
                          respuesta,
                          operador,
                          valor,
                        }) => {
                          req.post(
                            `${API_URL}formularios/items/dependencias/store`,
                            {
                              formulario_item: question_id,
                              formulario_item_dependencia:
                                formulario_item_id_dependencia,
                              formulario_respuesta: respuesta,
                              operador,
                              valor,
                            }
                          );
                        }
                      );

                      if (formattedMatrizSubAnswer) {
                        req.post(`${API_URL}formularios/respuestas/store`, {
                          respuesta: "N/A",
                          peso: 0,
                          sub_respuesta: formattedMatrizSubAnswer,
                          formulario_item: question_id
                        });
                      } else {
                        // save options for answers, if any
                        fieldresponsesSelected.forEach(({ respuesta, peso }) => {
                          req.post(`${API_URL}formularios/respuestas/store`, {
                            respuesta,
                            peso,
                            formulario_item: question_id,
                            sub_respuesta: formattedMatrizSubAnswer
                          });
                        });
                      }

                      this.props.history.push({
                        pathname: "/formulario/" + formCreation,
                        state: {
                          alertShow: true,
                          alertMessage: "Pregunta Creada",
                          alertType: "success",
                        },
                      });
                    });
                }
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <FS
                      label="Pregunta *"
                      name="pregunta"
                      errors={formik.errors}
                      col={[4]}
                    >
                      <Field type="text" name="pregunta" />
                    </FS>
                    <FS
                      label="Codificación *"
                      name="codificacion"
                      errors={formik.errors}
                      col={[4]}
                    >
                      <Field type="text" name="codificacion" />
                    </FS>
                    <FS
                      label="Tipo de Pregunta *"
                      name="question_type_id"
                      errors={formik.errors}
                      col={[4]}
                    >
                      <Field name="question_type_id" as="select">
                        <option value=""> Selecciona </option>
                        {questiontypes.map((p) => (
                          <option value={p.id}> {p.nombre}</option>
                        ))}
                      </Field>
                    </FS>
                    <FS
                      label="Sección"
                      name="seccion_id"
                      errors={formik.errors}
                      col={[4]}
                    >
                      <Field name="seccion_id" as="select">
                        <option value=""> Selecciona </option>
                        {sectionsFiltered
                          .filter(x => !!x.activo)
                          .map((p) => (
                            <option key={p.id} value={p.id}>
                              {" "}
                              {p.nombre + ' - ' + p.id}
                            </option>
                          ))}
                      </Field>
                    </FS>
                    <FS
                      label="Orden"
                      name="orden"
                      errors={formik.errors}
                      col={[4]}
                    >
                      <Field type="number" name="orden" />
                    </FS>
                    <FS
                      label="Índice"
                      name="indice"
                      errors={formik.errors}
                      col={[4]}
                    >
                      <Field type="number" name="indice" />
                    </FS>
                    <FS
                      label=""
                      name="required"
                      errors={formik.errors}
                      col={[4]}
                    >
                      <label>
                        <Field type="checkbox" name="required" /> Campo
                        Requerido
                      </label>
                    </FS>
                  </Row>

                  {this.renderMatrizQuestions(formik, fieldId)}
                  {/*{this.renderMatrizAnswers(formik, fieldId)} */}
                  <br />
                  <Row>
                    <Col lg={12}>
                      <strong>Preguntas Dependientes</strong>
                      <div className="subBox">
                        <div className="form-group">
                          <Row>
                            <p style={{ marginBottom: "0" }}>
                              Esta pregunta se mostrará cuando:
                            </p>
                            <FS
                              label="Pregunta"
                              name="dependence_formfield"
                              errors={formik.errors}
                              col={[3]}
                            >
                              <Field
                                name="dependence_formfield"
                                onChange={(e) =>
                                  this.selectDependence_formfield(
                                    e.target.value,
                                    formik
                                  )
                                }
                                ref={this.question}
                                as="select"
                              >
                                <option value=""> Selecciona </option>
                                {this.state.selectableQuestions.map((elem) => (
                                  <option
                                    key={elem.id}
                                    value={elem.id}
                                  >{` ${elem.pregunta} `}</option>
                                ))}
                              </Field>
                            </FS>
                            <FS
                              label="Operador"
                              name="dependence_operator"
                              errors={formik.errors}
                              col={[3]}
                            >
                              <Field
                                ref={this.operator}
                                name="dependence_operator"
                                as="select"
                              >
                                <option value=""> Selecciona </option>
                                <option value="0"> Sea Igual a</option>
                                <option value="3"> Sea Diferente a </option>
                              </Field>
                            </FS>
                            {formik.values.dependence_operator !== "4" ? (
                              <FS
                                label="Respuesta"
                                name="dependence_response"
                                errors={formik.errors}
                                col={[3]}
                              >
                                {this.renderAnswerField(formik)}
                              </FS>
                            ) : (
                              <Field
                                ref={this.answerDependence}
                                value=""
                                type="text"
                                hidden
                                name="dependence_response"
                              />
                            )}

                            <Col lg={3}>
                              <br />
                              <Button
                                variant="primary "
                                className="secondaryButton"
                                disabled={
                                  !formik.values.dependence_formfield ||
                                  !formik.values.dependence_operator ||
                                  !formik.values.dependence_response
                                }
                                onClick={() => this.addDependence(formik)}
                              >
                                Añadir
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                        <MaterialTable
                          title=""
                          columns={[
                            {
                              title: "Pregunta",
                              field: "formulario_item_id_dependencia",
                              render: (rowData) => {
                                return formfieldsAll.find(
                                  (elem) =>
                                    elem.id ===
                                    parseInt(
                                      rowData.formulario_item_id_dependencia
                                    )
                                )?.pregunta;
                              },
                              customFilterAndSearch: (term, rowData) =>
                                formfieldsAll
                                  .find(
                                    (elem) =>
                                      elem.id ===
                                      parseInt(
                                        rowData.formulario_item_id_dependencia
                                      )
                                  )
                                  ?.pregunta?.toLowerCase()
                                  .indexOf(term.toLowerCase()) !== -1,
                            },
                            {
                              title: "Operador",
                              field: "operador",
                              render: (rowData) =>
                                returnOperator(rowData.operador),
                            },
                            {
                              title: "Respuesta",
                              field: "respuesta",
                              render: (rowData) => rowData.valor,
                              customFilterAndSearch: (term, rowData) =>
                                rowData.valor.indexOf(term.toLowerCase()) !== -1,
                            },
                            {
                              title: "Estatus",
                              field: "activo",
                              render: (rowData) =>
                                !rowData.id
                                  ? "Nueva"
                                  : rowData.activo
                                    ? "Activo"
                                    : "Inactivo",
                            },
                          ]}
                          options={{
                            search: false,
                            selection: false,
                            searchFieldAlignment: "right",
                            paging: false,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            draggable: false,
                          }}
                          localization={{ header: { actions: "Acciones" } }}
                          data={dependentfieldsSelected.filter(
                            (data) => data.activo
                          )}
                          actions={[
                            (rowData) => ({
                              icon: HistoryIcon,
                              tooltip: "Auditoria",
                              onClick: (event, rowData) =>
                                this.showAudit(rowData),
                            }),

                            (rowData) => ({
                              icon: DoNotDisturbOnOutlinedIcon,
                              tooltip: "Desactivar",
                              onClick: (event, rowData) =>
                                this.action_RowOptionsDependence(
                                  "Desactivar",
                                  rowData
                                ),
                              disabled: !rowData.activo || !rowData.id,
                            }),
                            (rowData) => ({
                              icon: CheckCircleOutlinedIcon,
                              tooltip: "Activar",
                              onClick: (event, rowData) =>
                                this.action_RowOptionsDependence(
                                  "Activar",
                                  rowData
                                ),
                              disabled: rowData.activo || !rowData.id,
                            }),
                          ]}
                        />
                      </div>
                    </Col>
                  </Row>

                  <br />
                  {this.renderPossibleAnswers(formik)}
                  <Button
                    type="submit"
                    className="buttonSubmit"
                    variant="primary "
                  >
                    {formik.isSubmitting ? "Guardando..." : "Guardar"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </CSSTransition>
      </Container>
    );
  }
}

function returnOperator(value) {
  switch (value) {
    case "0":
      return "Sea Igual a";
    case "1":
      return "Sea Menor o igual a";
    case "2":
      return "Sea Mayor o igual a";
    case "3":
      return "Sea Diferente a";
    default:
      return "Operador inválido";
  }
}
export default withRouter(FormFieldCreateEdit);
