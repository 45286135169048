import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { clearReportData, loadReportData } from './ReportDataService.js';
import unorm from 'unorm';
import { getCustomReportFilterFunction } from '../utils/getCustomReportFilterFunction'

function removeAccents(str) {
  return unorm.nfkd(str).replace(/[\u0300-\u036f]/g, '');
}
const customFilter = (term, rowData) => {
  term = removeAccents(term).toLowerCase();
  const rowDataString = Object.values(rowData).join(' ').toLowerCase();
  return rowDataString.includes(term);
};


export function TableReporte9({ numeroReporte, origen }) {
  const [tableData, setTableData] = useState([]);
  const [tipoParticipante, setTipoParticipante] = useState('')
  useEffect(() => {
    const tData = loadReportData();
    setTableData((tData ?? []).map(item => ({
      ...item,
      porcentaje: `${(item?.porcentaje || 0)}%`,
      motivo: item?.motivo || '',
      observacion: item?.observacion || ''
    })));
    if (origen) setTipoParticipante('tipo_participante');
    if (['11', '13', '14', '15', '16', '18'].includes(numeroReporte)) setTipoParticipante('tipo');
    return () => clearReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadCsv = () => {
    if (tableData.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'reporte',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of tableData) {
      const row = { ...elem };
      delete row.tableData; // remove this property added by MaterialTable
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };

  function getPrograma() {
    if (['15', '16', '18', '13'].includes(numeroReporte)) {
      return { title: 'Programas', customFilterAndSearch: getCustomReportFilterFunction('programas'), field: 'programas' }
    } else if ('14' === numeroReporte) {
      return { title: 'Programas', customFilterAndSearch: getCustomReportFilterFunction('programas_asistio'), field: 'programas_asistio' }
    }
    return { title: 'Programa', customFilterAndSearch: getCustomReportFilterFunction('programa'), field: 'programa' }


  }


  return (
    <div className='container'>
      <div className='mainBox'>
        <div className='d-flex justify-content-end'>
          {tableData.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
        <MaterialTable
          customFilterAndSearch={customFilter}
          title=''
          columns={[
            {
              title: 'Organización', field: 'organizacion',
              customFilterAndSearch: getCustomReportFilterFunction('organizacion')
            },

            getPrograma(),
            ...(numeroReporte === '16' ? [
              {
                title: 'Fecha de Registro', field: 'fecha_registro',
                customFilterAndSearch: getCustomReportFilterFunction('fecha_registro')
              },
              {
                title: 'Encuestas', field: 'encuestas',
                customFilterAndSearch: getCustomReportFilterFunction('encuestas')
              }
            ] : []),
            {
              title: 'Código', field: 'codigo',
              customFilterAndSearch: getCustomReportFilterFunction('codigo')
            },
            {
              title: 'Nombres', field: 'nombres',
              customFilterAndSearch: getCustomReportFilterFunction('nombres')
            },
            {
              title: 'Apellidos', field: 'apellidos',
              customFilterAndSearch: getCustomReportFilterFunction('apellidos')
            },
            {
              title: 'Sexo',
              field: 'sexo',
              cellStyle: { textTransform: 'capitalize' },
            },
            { title: 'Género', field: 'genero' },
            {
              title: 'Fecha Nacimiento',
              field: 'fecha_nacimiento',
              customFilterAndSearch: getCustomReportFilterFunction('fecha_nacimiento'),
              render: (rowData) => {
                const parsedDate = parse(
                  rowData.fecha_nacimiento,
                  'dd-MM-yyyy',
                  new Date()
                );
                return format(parsedDate, 'dd/MM/yyyy');
              },
            },
            { title: 'Edad Actual', field: 'edad' },
            { title: 'Edad en el Registro', field: 'edad_registro' },
            { title: tipoParticipante ? 'Tipo de Participante' : tipoParticipante, field: tipoParticipante },
            { title: 'Cuenta con Discapacidad', field: 'discapacidad' },
            {
              title: 'Pertenece a algún Grupo Vulnerable?',
              field: 'vulnerabilidad'
            },
            { title: 'Donde Naciste', field: 'donde_naciste' },
            { title: 'Calle y Número', field: 'direccion' },
            { title: 'Barrio', field: 'barrio' },
            {
              title: 'Sector', field: 'sector',
              customFilterAndSearch: getCustomReportFilterFunction('sector'),
            },
            { title: 'Municipio', field: 'municipio', customFilterAndSearch: getCustomReportFilterFunction('municipio'), },
            { title: 'Provincia', field: 'provincia', customFilterAndSearch: getCustomReportFilterFunction('provincia'), },
            { title: 'Celular', field: 'celular' },
            { title: 'Teléfono', field: 'telefono' },
            { title: 'Nombre y Apellido Contacto', field: 'contacto' },
            { title: 'Parentesco', field: numeroReporte === '16' ? 'Parentesco' : 'tipo_contacto' },
            { title: 'Teléfono Contacto', field: 'telefono_contacto' },
            ...(origen ? [
              { title: 'COMPLETÓ EL PORCENTAJE DEL PROGRAMA', field: 'completo' },
              { title: 'PORCENTAJE', field: 'porcentaje' },

              ...(numeroReporte === '9' ? [
                { title: 'Nombre de la Actividad', field: 'actividad' },
                { title: 'ID de la Actividad', field: 'id_actividad' },
                { title: 'Fecha de Inicio', field: 'fecha_inicio' },
                { title: 'Fecha de Cierre', field: 'fecha_final' },
              ] : []),

              { title: 'Observación', field: 'observacion' },
              { title: 'Motivo', field: 'motivo' },
            ] : []
            ),



          ]}
          options={{
            search: true,
            draggable: false,
            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 60,
          }}
          data={tableData}
        />
      </div>
    </div>
  );
}

export default TableReporte9;
