import { ErrorMessage, Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { number, object, string } from 'yup';
import Alert from '../common/Alert';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import FS from '../components/FieldStructure';
import { useOfflinePolls } from '../App';
import { openDB } from 'idb';

const DB_NAME = 'pollsDatabase';
const STORE_NAME = 'pollsStore';
export const offlinePollsKeyName = 'offline-polls';

// Initialize or open the database
const initDB = async () => {
    return openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
            }
        },
    });
};

// Retrieve all polls from IndexedDB
export const getAllPolls = async () => {
    const db = await initDB();
    return await db.getAll(STORE_NAME);
};

// Insert new polls into IndexedDB
export const insertPolls = async (newPolls) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    for (const poll of newPolls) {
        await store.add(poll);
    }

    await tx.done;
};

// Update a specific poll in IndexedDB
export const updatePoll = async (id, updateFn) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    const poll = await store.get(id);
    if (poll) {
        const updatedPoll = updateFn(poll);
        await store.put(updatedPoll);
    }

    await tx.done;
};

export const GeneratePollOffline = () => {
    const [alert, setAlert] = useState({
        show: false,
        message: '',
        type: 'success',
    });

    const [formularios, setFormularios] = useState([]);
    const { data: offlinePolls } = useOfflinePolls();
    const initialValues = {};

    const validationSchema = object().shape({
        // formulario: string().required('Nombre es un campo requerido'),
        // cantidad: number().required('Correo electrónico es un campo requerido'),
    });

    useEffect(() => {
        req.get(`${API_URL}selectores/formularios`).then((response) => {
            setFormularios(response?.data?.data ?? []);
        });
    }, []);

    const saveData = (values, { setSubmitting }) => {
        const { cantidad, formulario } = values;
        req
            .post(`${API_URL}offline/encuestas/store`, { cantidad, formulario })
            .then((response) => {
                Promise.all(response.data.data.map(async (item) => {
                    const { data } = await req.get(`${API_URL}encuestas/${item.id}/show`);
                    return data.data;
                }))
                    .then((data) => {
                        console.log({ data });
                        insertPolls(data).then(() => {
                            setAlert({
                                show: true,
                                message: 'Encuestas creadas.',
                                type: 'success',
                            });
                            setTimeout(() => {
                                setAlert({ show: false, message: '', type: 'success' });
                            }, 5000);
                        });
                    });
            })
            .catch((errorResponse) => {
                const message = errorResponse?.response?.data?.message;
                setAlert({
                    show: true,
                    type: 'text-danger',
                    message,
                });
                setTimeout(() => {
                    setAlert({ show: false, message: '', type: 'success' });
                }, 5000);
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <div className='container'>
            <div className='titleButtons'>
                <div className='nav justify-content-end'>
                    <Alert {...alert} />
                    {/* <div className='nav-item'>
                        <Link className='nav-link' to='/cambiar-contraseña'>
                            Cambiar contraseña
                        </Link>
                    </div> */}
                </div>
            </div>
            <div className='mainBox'
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <SpinnerPlaceholder isLoading={false}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={saveData}

                    >
                        {({
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            handleReset,
                            isSubmitting,
                            setFieldValue,
                            errors
                        }) => (
                            <form onSubmit={handleSubmit} onReset={handleReset} style={{}}>

                                <div style={{ fontWeight: 'bold', paddingBottom: 10 }}>Encuestas reservadas: {(offlinePolls || []).length}</div>
                                <div className='form-group'>
                                    <label className='form-label'>* Formulario</label>
                                    <select
                                        name='formulario'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        <option value=''>Selecciona...</option>
                                        {formularios.map((formulario) => (
                                            <option key={`form_${formulario.id}`} value={formulario.id}>
                                                {formulario.nombre}
                                            </option>
                                        ))}
                                    </select>
                                    <ErrorMessage
                                        component='p'
                                        name='formulario'
                                        className='small text-danger'
                                    />
                                </div>
                                <FS label="Cantidad" name="cantidad" errors={errors} col={[6]} >
                                    <Field type="number" name="cantidad" />
                                </FS>

                                <button type='submit' className='btn btn-primary buttonSubmit'>
                                    {isSubmitting ? 'Guardando...' : 'Guardar'}
                                </button>
                            </form>
                        )}
                    </Formik>
                </SpinnerPlaceholder>
            </div>
        </div>
    );
};

export default withRouter(GeneratePollOffline);
