

export default {
	API:
		// "https://api-entrena.apps-dev.madlab.com.do/api/"
		"https://api-entrena-v2.entrenadr.com/api/",
}

export const API_URL =
	// "https://api-entrena.apps-dev.madlab.com.do/api/"

	'https://api-entrena-v2.entrenadr.com/api/';
