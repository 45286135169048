import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { clearReportData, loadReportData } from './ReportDataService.js';
import unorm from 'unorm';
import { getCustomReportFilterFunction } from '../utils/getCustomReportFilterFunction'

function removeAccents(str) {
  return unorm.nfkd(str).replace(/[\u0300-\u036f]/g, '');
}
const customFilter = (term, rowData) => {
  term = removeAccents(term).toLowerCase();
  const rowDataString = Object.values(rowData).join(' ').toLowerCase();
  return rowDataString.includes(term);
};


export function TableReporte19({ numeroReporte, origen }) {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const tData = loadReportData();
    setTableData((tData ?? []).map(item => ({
      ...item,
      porcentaje: `${(item?.porcentaje || 0)}%`,
      motivo: item?.motivo || '',
      observacion: item?.observacion || ''
    })));

    return () => clearReportData();
  }, []);

  const downloadCsv = () => {
    if (tableData.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'reporte',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of tableData) {
      const row = { ...elem };
      delete row.tableData; // remove this property added by MaterialTable
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };

  return (
    <div className='container'>
      <div className='mainBox'>
        <div className='d-flex justify-content-end'>
          {tableData.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
        <MaterialTable
          customFilterAndSearch={customFilter}
          title=''
          columns={[
            {
              title: 'Organización', field: 'organizacion',
              customFilterAndSearch: getCustomReportFilterFunction('organizacion')
            },

            {
              title: 'Código', field: 'codigo',
              customFilterAndSearch: getCustomReportFilterFunction('codigo')
            },
            { title: 'Programa', field: (numeroReporte === '15' || numeroReporte === '18') ? 'programas' : 'programa', customFilterAndSearch: getCustomReportFilterFunction((numeroReporte === '15' || numeroReporte === '18') ? 'programas' : 'programa') },
            {
              title: 'Nombres', field: 'nombres',
              customFilterAndSearch: getCustomReportFilterFunction('nombres')
            },
            {
              title: 'Apellidos', field: 'apellidos',
              customFilterAndSearch: getCustomReportFilterFunction('apellidos')
            },
            {
              title: 'Sexo',
              field: 'sexo',
              cellStyle: { textTransform: 'capitalize' },
            },
            { title: 'Género', field: 'genero' },
            {
              title: 'Fecha Nacimiento',
              field: 'fecha_nacimiento',
              customFilterAndSearch: getCustomReportFilterFunction('fecha_nacimiento'),
              render: (rowData) => {
                const parsedDate = parse(
                  rowData.fecha_nacimiento,
                  'dd-MM-yyyy',
                  new Date()
                );
                return format(parsedDate, 'dd/MM/yyyy');
              },
            },
            { title: 'Edad Actual', field: 'edad' },
            { title: 'Edad en el Registro', field: 'edad_registro' },

            { title: 'COMPLETÓ EL PORCENTAJE DEL PROGRAMA', field: 'completo_porcentaje' },
            { title: 'PORCENTAJE', field: 'porcentaje' },
            ...(numeroReporte === '19' ? [
              { title: 'Nombre de la Actividad', field: 'actividad' },
              { title: 'ID de la Actividad', field: 'id_actividad' },
              { title: 'Fecha de Inicio', field: 'fecha_inicio' },
              { title: 'Fecha de Cierre', field: 'fecha_final' },
            ] : []),
            { title: 'Observación', field: 'observacion' },
            { title: 'Motivo', field: 'motivo' },
            { title: 'Cuenta Con una Discapacidad', field: 'discapacidad' },
            {
              title: 'Pertenece a algún Grupo Vulnerable?',
              field: 'vulnerabilidad'
            },
            { title: '¿Dónde Naciste?', field: 'donde_naciste' },
            { title: 'Calle y número', field: 'direccion' },
            { title: 'Sector', field: 'sector' },
            { title: 'Municipio', field: 'municipio' },
            { title: 'Provincia', field: 'provincia' },
            { title: 'Celular', field: 'celular' },
            { title: 'Teléfono', field: 'telefono' },
            { title: 'Nombre y Apellido Contacto', field: 'contacto' },
            { title: 'Parentesco', field: 'tipo_contacto' },
            { title: 'Teléfono Contacto', field: 'telefono_contacto' },
            { title: 'Tipo de Participante', field: 'tipo' },
          ]}
          options={{
            search: true,
            draggable: false,
            emptyRowsWhenPaging: false,
            pageSize: 20,
          }}
          data={tableData}
        />
      </div>
    </div>
  );
}

export default TableReporte19;
