import React, { Component, useEffect, useState } from "react";
import { Row, Modal, Container, Nav } from "react-bootstrap";
import DatePickerField from "../common/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter, } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from "formik";
import { validationSchema } from "./_formSettings";
import { CSSTransition } from "react-transition-group";
import { Link } from 'react-router-dom';
import FS from "../components/FieldStructure";
import Alerts from "../common/Alerts";
import $ from "jquery";
import Utils from '../utils/config';
import {
  INDIRECT_FORMS
} from '../configurations/Configurations';
import AuthService from "../services/auth.service";
import { API_URL } from '../utils/config';
import { req } from '../utils/request.js';
import axios from 'axios';
import { ROLE_SYSTEM_ADMIN } from '../utils/roles';
import MultiSelect from '../components/MultiSelect';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { PatternFormat } from 'react-number-format';
import { removeUnderscoreAndHyphen } from "../poll/PollQuestion.jsx";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min.js";
import {
  queryClient,
  useConfig,
  useParticipantes,
  useSelectorDocumentos,
  useSelectoresFormularios,
  useSelectoresOrganizaciones,
  useSelectoresPaises,
  useSelectorGeneroParticipantes,
  useSelectorMunicipios,
  useSelectorProvincias,
  useSelectorSectores,
  useSelectorTipoDiscapacidades,
  useSelectorTipoVulnerabilidades,
  useTipoParticipantes
} from "../App.js";
import { useOfflineSync } from "../utils/offline/useOfflineSync.js";

function generateRandomCode(length = 8, useLetters = true, useNumbers = true, useSpecialChars = false) {
  let characters = '';
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?';

  // Build the character set based on the options
  if (useLetters) characters += letters;
  if (useNumbers) characters += numbers;
  if (useSpecialChars) characters += specialChars;

  // Ensure there's at least one set of characters
  if (characters.length === 0) throw new Error("No characters selected for generating the code.");

  // Generate the random code
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

function generateCurrentTime() {
  const now = new Date();

  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

  // Append microseconds as zeros
  const microseconds = "000000";

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}Z`;
}

function calculateAge(birthDate) {
  const today = new Date();
  const [year, month, day] = birthDate.split("-").map(Number);
  const birthDateObj = new Date(year, month - 1, day); // Month is 0-based

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  const dayDiff = today.getDate() - birthDateObj.getDate();

  // Adjust age if current date is before the birthday in the current year
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
}

function Wrapper(props) {
  const publicPollType = props?.publicPollType;
  const beneficiario_id = props?.match?.params?.beneficiario_id;
  const { isOnline, performMutation } = useOfflineSync();

  function fetchOnlineData() {

    // const requestConfigs = req.get(`${API_URL}config`, { params: { key: 'entryForm' } });
    const requestAllConfigs = publicPollType ? [] : req.get(`${API_URL}config`, { params: { paginacion: 999999 } });
    const requestForms = publicPollType ? { data: { data: [] } } : req.get(`${API_URL}selectores/formularios`);
    const requestPaises = req.get(`${API_URL}${publicPollType ? 'enlaces/' : ''}selectores/paises`);
    const requestorganizations = req.get(`${API_URL}${publicPollType ? 'enlaces/' : ''}selectores/organizaciones`);
    const tiposDiscapacidad = req.get(`${API_URL}${publicPollType ? 'enlaces/' : ''}selectores/tipos/discapacidad`);
    const tiposVulnerabilidad = req.get(`${API_URL}${publicPollType ? 'enlaces/' : ''}selectores/tipos/vulnerabilidad`);
    const tiposGenero = req.get(`${API_URL}${publicPollType ? 'enlaces/' : ''}selectores/generos_particulares`);
    const documentos = beneficiario_id && publicPollType ? req.get(`${API_URL}selectores/documentos?participante=${beneficiario_id}`) : []
    const provincias = req.get(`${API_URL}${publicPollType ? 'enlaces/' : ''}selectores/provincias?pais=62`);
    const participante = beneficiario_id ? req.get(`${API_URL}participantes/${beneficiario_id}/show`) : null;

    axios.all([null, requestForms,
      requestPaises, requestorganizations, requestAllConfigs,
      tiposDiscapacidad, tiposVulnerabilidad, tiposGenero, documentos, provincias, participante]).then(axios.spread((...responses) => {

        // const responseConfigs = responses[0].data.data || responses[0].data;
        const responseForms = responses[1].data.data || responses[1].data;
        const responsePaises = responses[2]?.data?.data ?? [{ id: 62, nombre: 'República Dominicana' }];
        const responseTipoDiscapacidad = responses[5]?.data?.data || [];
        const responseTiposVulnerabilidad = responses[6]?.data?.data || [];
        const responseGeneros = responses[7]?.data?.data || [];
        const responseorganizations = responses[3].data.data || responses[3]?.data;
        const responseAllConfigs = responses[4]?.data?.data || responses[4]?.data;
        const responseDocumentos = responses[8]?.data?.data || responses[8]?.data;
        const responseProvincias = responses[9]?.data?.data || responses[9]?.data;
        const responseParticipante = responses[10]?.data?.data || responses[9]?.data;

        const entryFormConfig = (responseAllConfigs || []).find(x => x.key === 'entryForm');

        const fetchedData = {
          ...props,
          formularios: responseForms,
          entryFormConfig,
          organizaciones: responseorganizations,
          paises: responsePaises,
          tipoDiscapacidades: responseTipoDiscapacidad,
          tipoVulnerabilidades: responseTiposVulnerabilidad,
          generoParticipantes: responseGeneros,
          documentos: responseDocumentos,
          generoParticipantes: responseGeneros,
          provincias: responseProvincias,
          municipios: [],
          sectores: [],
          tipoParticipantes: [],
          publicPollType: props?.publicPollType,
          performMutation,
          isOnline,
          participante: responseParticipante,
          config: responseAllConfigs,
        }
        setFechedData(fetchedData)
      })).catch(errors => {
        console.error(errors);
        // react on errors.
      })
  }

  useEffect(() => {
    if (isOnline) {
      fetchOnlineData();
    }
  }, []);
  const { data: config } = useConfig();
  const { data: formularios } = useSelectoresFormularios();
  const entryFormConfig = (config || []).find(x => x.key === 'entryForm');
  const { data: organizaciones } = useSelectoresOrganizaciones({ publicPollType });
  const { data: paises } = useSelectoresPaises({ publicPollType });
  const { data: tipoDiscapacidades } = useSelectorTipoDiscapacidades({ publicPollType });
  const { data: tipoVulnerabilidades } = useSelectorTipoVulnerabilidades({ publicPollType });
  const { data: documentos } = useSelectorDocumentos();
  const { data: generoParticipantes } = useSelectorGeneroParticipantes({ publicPollType });
  const { data: provincias } = useSelectorProvincias({ publicPollType });
  const { data: municipios } = useSelectorMunicipios({ publicPollType });
  const { data: sectores } = useSelectorSectores({ publicPollType });
  const { data: participantes } = useParticipantes();
  const { data: tipoParticipantes } = useTipoParticipantes();

  const [fetchedData, setFechedData] = useState();

  const participante = (participantes || []).find(x => x.id === parseInt(beneficiario_id));
  // console.log({
  //   formularios,
  //   entryFormConfig,
  //   organizaciones,
  //   paises,
  //   tipoDiscapacidades,
  //   tipoVulnerabilidades,
  //   documentos,
  //   generoParticipantes,
  //   provincias,
  //   municipios,
  //   sectores,
  //   participante,
  //   participantes,
  //   props,
  //   tipoParticipantes,
  //   beneficiario_id
  // });
  console.log({ participante, beneficiario_id })
  if (isOnline && fetchedData) {
    return <Register
      {...fetchedData}
    />
  }

  // offline flow
  else if (
    !isOnline &&
    // (props?.publicPollType
    //   && organizaciones && generoParticipantes && tipoDiscapacidades
    //   && tipoVulnerabilidades && paises && provincias && municipios && sectores) || 

    (
      formularios && entryFormConfig
      && organizaciones && paises &&
      tipoDiscapacidades && tipoVulnerabilidades
      && documentos && generoParticipantes && provincias
      && sectores && (beneficiario_id ? !!participante : true) && tipoParticipantes)
  ) {

    return <Register
      {...props}
      {...{
        formularios,
        entryFormConfig,
        organizaciones,
        paises,
        tipoDiscapacidades,
        tipoVulnerabilidades,
        documentos,
        generoParticipantes,
        provincias,
        municipios,
        sectores,
        participante,
        participantes,
        tipoParticipantes,
        publicPollType: props?.publicPollType,
        performMutation
      }}
    />
  }

  return 'Loading....';
}

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      paises: [],
      provinces: [],
      popupValidateRegister: false,
      popupErrorRegister: false,
      alertShow: false,
      alertMessage: "",
      alertType: "",
      municipalities: [],
      organizations: [],
      municipalitiesToSelect: [],
      sectors: [],
      isEditing: false,
      editingData: [],
      sectorsToSelect: [],
      dataForSubmit: [],
      dataToPopupError: [],
      paisSeleccionado: 62,
      responseFormSubmited: [],
      entryform: [],
      configs: [],
      id: '',
      indirectForms: [],
      leaveValidation: true,
      files: [],
      tipoDiscapacidades: [],
      tipoVulnerabilidades: [],
      encuestaInscripcion: {},
      openPop: false,
      generos: []
    };
    this.show_PopUpValidateRegister = this.show_PopUpValidateRegister.bind(this);
    this.hide_PopUpValidateRegister = this.hide_PopUpValidateRegister.bind(this);
    this.show_PopUpErrorRegister = this.show_PopUpErrorRegister.bind(this);
    this.hide_PopUpErrorRegister = this.hide_PopUpErrorRegister.bind(this);
  }

  show_PopUpValidateRegister(data, data2) {
    this.setState({ popupValidateRegister: true, dataForSubmit: data, responseFormSubmited: data2 });
  }
  hide_PopUpValidateRegister(data) {
    this.props.history.push(
      {
        pathname: "/beneficiarios/"
      }
    );
  }
  show_PopUpErrorRegister(data) {
    this.setState({ popupErrorRegister: true, dataToPopupError: data });
  }
  hide_PopUpErrorRegister(data) {
    this.setState({ popupErrorRegister: false });

  }

  handleUnload = (event) => {
    if (this.state.popupValidateRegister) {
      return;
    }
    const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = confirmationMessage;

    return confirmationMessage;

  };

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    window.removeEventListener('beforeunload', this.handleUnload);
  }
  componentDidMount() {
    // window.history.pushState(null, null, window.location.href);

    window.addEventListener('beforeunload', this.handleUnload);
    $(".nav-link").removeClass("active");
    $(".nav-link[href='/registro']").addClass("active");
    // console.log({ props: this.props })

    const responseConfigs = this.props.entryFormConfig; // responses[0].data.data || responses[0].data;
    const responseForms = this.props.formularios || []; //responses[1].data.data || responses[1].data;
    const responsePaises = this.props.paises || []// responses[2]?.data?.data ?? [{ id: 62, nombre: 'República Dominicana' }];
    const responseTipoDiscapacidad = this.props.tipoDiscapacidades || [] // responses[5]?.data?.data || []
    const responseTiposVulnerabilidad = this.props.tipoVulnerabilidades || [] //responses[6]?.data?.data || []
    const responseGeneros = this.props.generoParticipantes || [] // responses[7]?.data?.data || [];
    this.paisSelected(62); // id: 62 = Dominican Republic
    const responseorganizations = this.props.organizaciones || [] // responses[3].data.data || responses[3].data;
    const responseAllConfigs = this.props.config || []// responses[4].data.data || responses[4].data;

    const entryform = responseForms.find(elem => elem.id === parseInt(responseConfigs?.value));
    const iF = JSON.parse(responseAllConfigs.find(x => x.key === INDIRECT_FORMS)?.value || null) || [];

    this.setState({
      indirectForms: iF,
      entryform: entryform,
      forms: responseForms,
      paises: responsePaises,
      organizations: responseorganizations,
      configs: responseConfigs,
      tipoDiscapacidades: responseTipoDiscapacidad,
      tipoVulnerabilidades: responseTiposVulnerabilidad,
      generos: responseGeneros
    });

    const beneficiario_id = this?.props?.match?.params?.beneficiario_id;
    if (beneficiario_id !== undefined) {

      const data = this.props.participante;
      this.paisSelected(data.pais.id);
      this.provinciaSelected(data.provincia.id).then(() => {
        return this.municipalitySelected(data.municipio.id);
      }).then(() => {
        this.setState({
          isEditing: true,
          editingData: data,
          sectorSeleccionado: data.sector !== null ? data.sector.id : data.sector,
          paisSeleccionado: data.pais_nacimiento.id,
        });
        setTimeout(() => {
          this.setState({ show: true });
        }, 200);
      });

    } else {

      setTimeout(() => {
        this.setState({ show: true });
      }, 200);
    }


    this.props.isOnline && this.fetchFiles();
  }

  paisSelected(pais) {
    if (this.props.isOnline) {
      req.get(`${API_URL}${this.props.publicPollType ? 'enlaces/' : ''}selectores/provincias`, { params: { pais, } }).then(response => {
        const provinces = response.data.data ?? [];
        this.setState({ provinces });
      });
    } else {
      const provincias = (this.props.provincias || []).filter(provincia => provincia.pais_id === pais);

      this.setState({ provinces: provincias || [] });
    }


  }

  setPaisSeleccionado(e) {
    this.setState({ paisSeleccionado: e });
  }

  async fetchFiles() {
    const beneficiario_id = this.props.match.params.beneficiario_id;


    if (!beneficiario_id) {
      console.error('ID del beneficiario no está definido o es inválido.');
      this.setState({ files: [] });
      return;
    }

    try {
      const response = await req.get(`${API_URL}participantes/documentos?participante=${beneficiario_id}`);
      if (response.status === 200) {
        this.setState({ files: response.data.data });
      } else {
        console.error('Error en la solicitud de archivos:', response.status, response.statusText);

      }
    } catch (error) {
      console.error('Error en la solicitud de archivos:', error);

    }
  }

  async provinciaSelected(provincia) {
    this.setState({
      provinciaSeleccionada: provincia,
      municipioSeleccionado: null,
      sectorSeleccionado: null
    });
    if (provincia === null) {
      return;
    }
    if (this.props.isOnline) {
      const response = await req.get(`${API_URL}${this.props.publicPollType ? 'enlaces/' : ''}selectores/municipios`, {
        params: { provincia }
      })
      const municipalitiesToSelect = (response.data.data ?? []).sort((a, b) => {
        if (a.nombre < b.nombre) {
          return -1;
        } else if (a.nombre > b.nombre) {
          return 1;
        } else {
          return 0;
        }
      });
      this.setState({ municipalitiesToSelect });
      return response.data.data ?? [];
    } else {
      const municipalities = this.props.municipios
        .filter(municipio => municipio.provincia_id === provincia)
        .sort((a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          } else if (a.nombre > b.nombre) {
            return 1;
          } else {
            return 0;
          }
        })
      this.setState({ municipalitiesToSelect: municipalities });
      return municipalities
    }

  }
  municipalitySelected(municipio) {
    this.setState({ municipioSeleccionado: municipio, sectorSeleccionado: null });
    if (municipio === null) {
      return;
    }

    if (this.props.isOnline) {
      req.get(`${API_URL}${this.props.publicPollType ? 'enlaces/' : ''}selectores/sectores`, { params: { municipio } }).then(response => {
        this.setState({ sectorsToSelect: response.data.data ?? [] });
      });
    } else {
      const sectores = (this?.props?.sectores || []).filter(sector => sector.municipio_id === municipio)
      this.setState({ sectorsToSelect: sectores });
    }
  }
  redirectToForm(value) {
    const user = this.state.responseFormSubmited;
    this.props.history.push({
      pathname: `/formularios/${value.id}/${user.id}`
    });
  }


  getVulnerabilidadValue(vulnerabilidad) {
    if ([1, true, 'si', 'Si', 'SI'].includes(vulnerabilidad)) {
      return 1
    } else if ([0, false, 'no', 'No', 'NO'].includes(vulnerabilidad)) {
      return 0
    }
    return ' ';
  }

  render() {

    const {
      alertMessage,
      alertShow,
      entryform,
      isEditing,
      editingData,
      popupErrorRegister,
      popupValidateRegister,
      dataToPopupError,
      organizations,
      responseFormSubmited,
      alertType,
      indirectForms,
      leaveValidation
    } = this.state;

    const beneficiario_id = this?.props?.match?.params?.beneficiario_id;

    const auth = AuthService.getCurrentUser();

    const opcionesProvincias = [
      { label: 'Selecciona', value: null },
      ...this.state.provinces.map((p) => (
        {
          label: p.nombre,
          value: p.id
        }
      ))];
    const provinciaSeleccionada = opcionesProvincias.find(op => op.value === this.state.provinciaSeleccionada);

    const opcionesMunicipios = [
      { label: 'Selecciona', value: null },
      ...this.state.municipalitiesToSelect.map((m) => (
        {
          label: m.nombre,
          value: m.id
        }
      ))
    ];
    const municipioSeleccionado = opcionesMunicipios.find((m) => m.value === this.state.municipioSeleccionado);

    const opcionesSectores = [
      { label: 'Selecciona', value: null },
      ...this.state.sectorsToSelect.sort((a, b) => {
        return a.nombre - b.nombre;
      }).map((s) => (
        {
          label: s.nombre,
          value: s.id
        }
      ))
    ];
    const sectorSeleccionado = opcionesSectores.find(s => s.value === this.state.sectorSeleccionado);

    const opcionesPaises = [
      { label: 'Selecciona', value: null },
      ...this.state.paises.map(p => ({ label: p.nombre_espanol, value: p.id }))
    ];
    const paisSeleccionado = opcionesPaises.find(p => p.value === this.state.paisSeleccionado);

    const initialValues = {
      vulnerabilidad: this.getVulnerabilidadValue(editingData?.vulnerabilidad),
      tipo: editingData.tipo?.id.toString() ?? '1',
      registro: editingData.registro || "",
      nombres: editingData.nombres || "",
      apellidos: editingData.apellidos || "",
      pais: editingData.pais?.id ?? '62', // 62 = Dominican Republic
      provincia: editingData.provincia?.id || null,
      municipio: editingData.municipio?.id || null,
      sector: editingData.sector?.id || null,
      direccion: editingData.direccion || "",
      pais_nacimiento: editingData.pais_nacimiento?.id
        || 62,
      fecha_nacimiento: editingData.fecha_nacimiento || "",
      genero: editingData.genero || "Masculino",
      if_mobile_phone: !editingData.movil ? "no" : "si",
      movil: editingData.movil || "",
      email: editingData.email || "",
      if_facebook: !editingData.facebook ? "no" : "si",
      facebook: editingData.facebook || "",
      if_email: !editingData.email ? "no" : "si",
      contacto_nombre: editingData.contacto_nombre || "",
      contacto_tipo: editingData.contacto_tipo || "",
      contacto_telefono: editingData.contacto_telefono || "",
      organizacion: editingData.organizacion?.id || (auth?.user?.role?.nombre !== ROLE_SYSTEM_ADMIN ? auth?.user?.organizacion?.id : ""),
      telefono: editingData.telefono || "",
      if_home_phone: !editingData.movil ? "no" : "si",
      institucion: editingData.institucion || "",
      funcion: editingData.funcion || "",
      discapacidad: editingData.discapacidad,
      genero_particular: editingData.genero_particular || 'Hombre',
      tipo_discapacidad: editingData?.discapacidad?.id.toString(),
      genero_particular: editingData?.genero_particular?.id.toString(),
      tipo_vulnerabilidad: editingData?.vulnerabilidad?.id
    };
    return (
      <Container>
        <Prompt
          when={!this.state.popupValidateRegister && leaveValidation}
          message="Estás seguro de que deseas dejar la página? Tus cambios podrían no ser guardados!"
        />
        <div className="titleButtons">

          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          // in={this.state.show}
          in={true}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}

              enableReinitialize={true}
              onSubmit={(values, { setSubmitting, resetForm, }) => {
                setSubmitting(true);
                if (values.vulnerabilidad === 'Selecciona') {
                  delete values.vulnerabilidad;
                }
                delete values.if_email;
                delete values.if_facebook;
                delete values.if_mobile_phone;
                delete values.if_home_phone;
                delete values.discapacidad;
                values.activo = true;

                if (values.telefono) values.telefono = removeUnderscoreAndHyphen(values.telefono);
                if (values.movil) values.movil = removeUnderscoreAndHyphen(values.movil);
                if (values.contacto_telefono) values.contacto_telefono = removeUnderscoreAndHyphen(values.contacto_telefono);

                if (isEditing) {
                  // const formData = new FormData();
                  // let discapacidadPayload = null;


                  // if (values.discapacidad === 'No definido') {
                  //   discapacidadPayload = 'no-contesto';
                  // } else if (values.discapacidad === true) {
                  //   discapacidadPayload = '1';
                  // } else if (values.discapacidad === 'No') {
                  //   discapacidadPayload = '0';
                  // }

                  // formData.append('discapacidad', discapacidadPayload);
                  // req.post(`${API_URL}participantes/${this.props.match.params.beneficiario_id}/discapacidad`, formData);


                  // req.post(`${API_URL}participantes/${this.props.match.params.beneficiario_id}/update`, values).then((response) => {
                  //   this.props.history.push({
                  //     pathname: "/beneficiarios",
                  //     state: {
                  //       alertShow: true,
                  //       alertMessage: "El beneficiario ha sido actualizado",
                  //       alertType: "success",
                  //     },
                  //   });
                  // });


                  this.props.performMutation({
                    endpoint: `${API_URL}participantes/${this.props.match.params.beneficiario_id}/update`,
                    payload: values,
                    offlineFn: () => {
                      const payload = values;
                      const newParticipants = this.props.participantes.map(participante => {
                        if (participante.id === parseInt(beneficiario_id)) {
                          const newParticipant = {
                            ...participante,
                            registro: payload.registro,
                            "codigo": generateRandomCode(10, true, true, true),
                            "nombres": payload.nombres,
                            "apellidos": payload.apellidos,
                            "discapacidad":
                              this.props.tipoDiscapacidades.find(item => item.id === parseInt(payload.tipo_discapacidad)),
                            "vulnerabilidad": this.props.tipoVulnerabilidades.find(item => item.id === parseInt(payload.tipo_vulnerabilidad)),
                            "fecha_nacimiento": payload.fecha_nacimiento,
                            "direccion": payload.direccion,
                            "genero": payload.genero,
                            "movil": payload.movil,
                            "telefono": payload.telefono,
                            "contacto_nombre": payload.contacto_nombre,
                            "contacto_tipo": payload.contacto_tipo,
                            "contacto_telefono": payload.contacto_telefono,
                            "organizacion": this.props.organizaciones.find(item => item.id === parseInt(payload.organizacion)),
                            "pais": this.props.paises.find(item => item.id === parseInt(payload.pais)),
                            "provincia": this.props.provincias.find(item => item.id === parseInt(payload.provincia)),
                            "municipio": this.props.municipios.find(item => item.id === parseInt(payload.municipio)),
                            "sector": this.props.sectores.find(item => item.id === parseInt(payload.sector)),
                            "activo": payload.activo,
                            "creado": generateCurrentTime(),
                            "actualizado": generateCurrentTime(),
                          };

                          return newParticipant
                        }
                        return participante;
                      });

                      queryClient.setQueryData(['participantes'], () => newParticipants);
                    },
                    keysToRevalidate: ['participantes'],
                  }).then(() => {
                    this.props.history.goBack();
                    // this.props.history.push({
                    //   pathname: "/beneficiarios",
                    //   state: {
                    //     alertShow: true,
                    //     alertMessage: "El beneficiario ha sido actualizado",
                    //     alertType: "success",
                    //   },
                    // });
                  });


                } else {
                  console.log('is not editing');
                  if (values.tipo === "1" && values.fecha_nacimiento === "") {
                    values.fecha_nacimiento = "1980-01-01"
                  }
                  console.log("executing performmutation with enlaces", 'publicPollType', this.props.publicPollType)
                  const code = this.props?.match?.params?.code;
                  this.props.performMutation({
                    endpoint: `${API_URL}${this.props.publicPollType ? `enlaces/encuestas/registro/beneficiario/${code}/store` : "participantes/store"}`,
                    payload: values,
                    offlineFn: () => {//
                      const payload = values;
                      const currentAge = calculateAge(values.fecha_nacimiento);
                      const newParticipant = {
                        registro: payload.registro,
                        "creado": generateCurrentTime(),
                        "actualizado": generateCurrentTime(),
                        "id": parseInt((Math.random() * 10000000000000).toFixed(0)),
                        "apodo": null,
                        "edad": currentAge,
                        "edad_registro": currentAge,
                        "identificacion": null,
                        "genero_particular": null,
                        "instagram": null,
                        "twitter": null,
                        "contacto_nombre": null,
                        "contacto_tipo": null,
                        "contacto_movil": null,
                        "institucion": null,
                        "funcion": null,
                        "tipo": this.props.tipoParticipantes.find(tipoParticipante => tipoParticipante.id === parseInt(payload.tipo)),
                        "documentos": [],
                        "organizacion": this.props.organizaciones.find(organizacion => organizacion.id === parseInt(payload.organizacion)),
                        "pais_nacimiento": this.props.paises.find(item => item.id === parseInt(payload.pais_nacimiento)),
                        "barrio": null,
                        "encuestas": [],
                        "codigo": generateRandomCode(10, true, true, true),
                        "nombres": payload.nombres,
                        "apellidos": payload.apellidos,
                        "discapacidad":
                          this.props.tipoDiscapacidades.find(item => item.id === parseInt(payload.tipo_discapacidad)),
                        "vulnerabilidad": this.props.tipoVulnerabilidades.find(item => item.id === parseInt(payload.tipo_vulnerabilidad)),
                        "fecha_nacimiento": payload.fecha_nacimiento,
                        "direccion": payload.direccion,
                        "genero": payload.genero,
                        "movil": payload.movil,
                        "telefono": payload.telefono,
                        "contacto_nombre": payload.contacto_nombre,
                        "contacto_tipo": payload.contacto_tipo,
                        "contacto_telefono": payload.contacto_telefono,
                        "organizacion": this.props.organizaciones.find(item => item.id === parseInt(payload.organizacion)),
                        "pais": this.props.paises.find(item => item.id === parseInt(payload.pais)),
                        "provincia": this.props.provincias.find(item => item.id === parseInt(payload.provincia)),
                        "municipio": this.props.municipios.find(item => item.id === parseInt(payload.municipio)),
                        "sector": this.props.sectores.find(item => item.id === parseInt(payload.sector)),
                        "activo": payload.activo,
                        email: payload.email,
                        facebook: payload.facebook,
                        funcion: payload.funcion,
                        institucion: payload.institucion,
                      };
                      const participants = [...this.props.participantes, newParticipant]
                      queryClient.setQueryData(['participantes'], () => participants);
                      return { data: { data: newParticipant } };
                    },
                    keysToRevalidate: ['participantes']
                  })
                    .then((response) => {
                      console.log('then of executing performmutation with enlaces', response, 'publicPollType', this.props.publicPollType)
                      if (this.props.publicPollType) {
                        this.props.history.push(`/enlaces/participantes/llenado/${code}/${response.data.data.id}`)
                      } else {
                        resetForm();
                        setSubmitting(false);
                        this.setState({ id: response.data.data.codigo });
                        this.show_PopUpValidateRegister(values, response.data.data);
                      }
                    })
                    .catch((error) => {
                      console.error({ error });
                      if (error.response) {
                        this.show_PopUpErrorRegister(error.response.data);
                        setSubmitting(false);
                      }
                    });

                }
                this.setState({ leaveValidation: false });
                $(window).scrollTop(0);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>

                    <FS
                      label="Organización"
                      name="organizacion"
                      errors={errors}
                      col={[6]}
                    >
                      <Field name="organizacion" as="select">
                        <option value="null"> Selecciona </option>
                        {organizations.map((p) => (
                          <option key={p.id} value={p.id}> {p.nombre}</option>
                        ))}
                      </Field>
                    </FS>

                    <FS
                      label="Tipo de Participante"
                      name="tipo"
                      errors={errors}
                      col={[6]}
                    >
                      <Field
                        name="tipo"
                        as="select"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue('tipo', e.target.value);
                          if (e.target.value === '2') {
                            setFieldValue('fecha_nacimiento', '1984-01-01');
                          }
                          this.setState({ tipo: e.target.value });
                        }}
                      >
                        <option value="1"> Directo </option>
                        <option value="2"> Indirecto </option>
                      </Field>
                    </FS>
                    <FS
                      label="Fecha de registro"
                      name="registro"
                      errors={errors}
                      col={[6]}
                    >
                      <DatePickerField name="registro" />
                    </FS>
                    <FS label="Nombres" name="nombres" errors={errors} col={[6]}>
                      <Field type="text" name="nombres" />
                    </FS>
                    <FS
                      label="Apellidos"
                      name="apellidos"
                      errors={errors}
                      col={[6]}
                    >
                      <Field type="text" name="apellidos" />
                    </FS>
                    <div className='form-group col-6'>
                      <label className='form-label'>Provincia</label>
                      <MultiSelect
                        name='provincia'
                        defaultValue={provinciaSeleccionada}
                        value={provinciaSeleccionada}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange({
                            target: { name: 'provincia', value: e },
                          });
                          setFieldValue('municipio', null);
                          setFieldValue('sector', null);
                          this.provinciaSelected(e);
                        }}
                        options={opcionesProvincias}
                      />
                      <ErrorMessage
                        name='provincia'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label className='form-label'>Municipio</label>
                      <MultiSelect
                        name='municipio'
                        defaultValue={municipioSeleccionado}
                        value={municipioSeleccionado}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange({
                            target: { name: 'municipio', value: e },
                          });
                          setFieldValue('sector', null);
                          this.municipalitySelected(e);
                        }}
                        options={opcionesMunicipios}
                      />
                      <ErrorMessage
                        name='municipio'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                    <div className='form-group col-6'>
                      <label className='form-label'>Sector</label>
                      <MultiSelect
                        name='sector'
                        defaultValue={sectorSeleccionado}
                        value={sectorSeleccionado}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange({
                            target: { name: 'sector', value: e },
                          });
                          this.setState({ sectorSeleccionado: opcionesSectores.find(elem => elem.value === e) });
                        }}
                        options={opcionesSectores}
                      />
                      <ErrorMessage
                        name='sector'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                    {values.tipo === "1" ? (
                      <FS
                        label="Calle y Número"
                        name="direccion"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="direccion" />
                      </FS>
                    ) : null}
                    <FS
                      label="Fecha de nacimiento"
                      name="fecha_nacimiento"
                      errors={errors}
                      col={[6]}
                    >
                      <DatePickerField name="fecha_nacimiento" />
                    </FS>
                    <FS
                      label="¿Discapacidad?"
                      name="tipo_discapacidad"
                      errors={errors}
                      col={[6]}
                    >

                      <Field as="select" value={values.tipo_discapacidad}
                        onChange={(e) => {
                          if (e.target.value === 'Dato no recolectado') {

                          }
                          handleChange({
                            target: {
                              name: 'tipo_discapacidad',
                              value: e.target.value === 'Dato no recolectado'
                                ? null :
                                e.target.value
                            },
                          })
                        }}
                      >
                        <option>{'Dato no recolectado'} </option>
                        {this.state.tipoDiscapacidades.map(x => (
                          <option value={x.id}>{x.nombre} </option>
                        ))}
                      </Field>
                    </FS>

                    <div className='form-group col'>
                      <label className='form-label'>País de nacimiento</label>
                      <MultiSelect
                        name='pais_nacimiento'
                        defaultValue={paisSeleccionado}
                        value={paisSeleccionado}
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          this.setPaisSeleccionado(e);
                          handleChange({
                            target: { name: 'pais_nacimiento', value: e },
                          });

                        }}
                        options={opcionesPaises}
                      />
                      <ErrorMessage
                        name='pais_nacimiento'
                        className='small text-danger'
                        component='p'
                      />
                    </div>
                    <FS label="Sexo" name="genero" errors={errors} col={[6]}>
                      <Field name="genero" as="select">
                        <option value="Masculino"> Masculino </option>
                        <option value="Femenino"> Femenino </option>
                      </Field>
                    </FS>
                    {/* <FS label="Género" name="genero_particular" errors={errors} col={[6]}>
                      <Field name="genero_particular" as="select">
                        <option value="Dato no recolectado"> Dato no recolectado </option>
                        <option value="Hombre"> Hombre </option>
                        <option value="Mujer"> Mujer </option>
                        <option value="No Binario"> No Binario </option>
                        <option value="Otro"> Otro </option>
                        <option value="No deseo responder"> No deseo responder </option>
                      </Field>
                    </FS> */}
                    <FS
                      label="Género"
                      name="genero"
                      errors={errors}
                      col={[6]}
                    >

                      <Field as="select" value={values.genero_particular}
                        onChange={(e) => {
                          if (e.target.value === 'Dato no recolectado') {

                          }
                          handleChange({
                            target: {
                              name: 'genero_particular',
                              value: e.target.value === 'Dato no recolectado'
                                ? null :
                                e.target.value
                            },
                          })
                        }}
                      >
                        <option>{'Dato no recolectado'} </option>
                        {this.state.generos.map(x => (
                          <option value={x.id}>{x.nombre} </option>
                        ))}
                      </Field>
                    </FS>
                    <FS
                      label="¿Tienes teléfono celular?"
                      name="if_mobile_phone"
                      errors={errors}
                      col={[6]}
                    >
                      <Field name="if_mobile_phone" as="select">
                        <option value="null"> Selecciona </option>
                        <option value="no"> No </option>
                        <option value="si"> Si </option>
                      </Field>
                    </FS>

                    {values.if_mobile_phone === "si" ? (
                      <FS
                        label="Teléfono Celular"
                        name="movil"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="movil">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => (
                            <PatternFormat
                              format="###-###-####"
                              mask='_'
                              {...field}
                            />
                          )}
                        </Field>
                      </FS>
                    ) : null}
                    {values.tipo === "1" ? (
                      <FS
                        label="¿Tienes teléfono de casa?"
                        name="if_home_phone"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="if_home_phone" as="select">
                          <option value="null"> Selecciona </option>
                          <option value="no"> No </option>
                          <option value="si"> Si </option>
                        </Field>
                      </FS>
                    ) : null}
                    {values.tipo === "1" &&
                      values.if_home_phone === "si" ? (
                      <FS
                        label="Teléfono Casa"
                        name="telefono"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="telefono">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => (
                            <PatternFormat
                              format="###-###-####"
                              mask='_'
                              {...field}
                            />
                          )}
                        </Field>

                      </FS>
                    ) : null}
                    <FS
                      label="¿Tienes correo electrónico?"
                      name="if_email"
                      errors={errors}
                      col={[6]}
                    >
                      <Field name="if_email" as="select">
                        <option value="null"> Selecciona </option>
                        <option value="no"> No </option>
                        <option value="si"> Si </option>
                      </Field>
                    </FS>

                    {values.if_email === "si" ? (
                      <FS
                        label="Correo electrónico"
                        name="email"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="email" />
                      </FS>
                    ) : null}
                    {values.tipo === "1" ? (
                      <FS
                        label="¿Tienes Facebook?"
                        name="if_facebook"
                        errors={errors}
                        col={[6]}
                      >
                        <Field name="if_facebook" as="select">
                          <option value="null"> Selecciona </option>
                          <option value="no"> No </option>
                          <option value="si"> Si </option>
                        </Field>
                      </FS>
                    ) : null}
                    {values.tipo === "1" &&
                      values.if_facebook === "si" ? (
                      <FS
                        label="Usuario de Facebook"
                        name="facebook"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="facebook" />
                      </FS>
                    ) : null}
                    {values.tipo === "2" ? (
                      <FS
                        label="Institución"
                        name="institucion"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="institucion" />
                      </FS>
                    ) : null}
                    {values.tipo === "2" ? (
                      <FS
                        label="Función"
                        name="funcion"
                        errors={errors}
                        col={[6]}
                      >
                        <Field type="text" name="funcion" />
                      </FS>
                    ) : null}
                  </Row>
                  {values.tipo === "1" ? (
                    <div>
                      <strong>
                        En caso de no poder localizarte, ¿a quién podemos
                        contactar?
                      </strong>
                      <Row>
                        <FS
                          label="Nombre de la persona de contacto"
                          name="contacto_nombre"
                          errors={errors}
                          col={[6]}
                        >
                          <Field type="text" name="contacto_nombre" />
                        </FS>
                        <FS
                          label="Parentesco"
                          name="contacto_tipo"
                          errors={errors}
                          col={[6]}
                        >
                          <Field name="contacto_tipo" as="select">
                            <option value="null"> Selecciona </option>
                            <option value="Abuelo/a"> Abuelo/a </option>
                            <option value="Amigo/a"> Amigo/a </option>
                            <option value="Cunado/a"> Cuñado/a </option>
                            <option value="Esposo/a"> Esposo/a </option>
                            <option value="Hermano/a"> Hermano/a </option>
                            <option value="Madrastra/Padrastro"> Madrastra/Padrastro </option>
                            <option value="Madre"> Madre </option>
                            <option value="Padre"> Padre </option>
                            <option value="Padrino/Madrina"> Padrino/Madrina </option>
                            <option value="Primo/a"> Primo/a </option>
                            <option value="Suegro/a"> Suegro/a </option>
                            <option value="Tio/a"> Tio/a </option>
                            <option value="Vecino/a"> Vecino/a </option>
                          </Field>
                        </FS>
                        <FS
                          label="Teléfono de la persona de contacto"
                          name="contacto_telefono"
                          errors={errors}
                          col={[6]}
                        >
                          <Field name="contacto_telefono">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <PatternFormat
                                format="###-###-####"
                                mask='_'
                                {...field}
                              />
                            )}
                          </Field>

                        </FS>
                        <FS
                          label="¿Formas parte de algún grupo excluido o vulnerable?"
                          name="tipo_vulnerabilidad"
                          errors={errors}
                          col={[6]}

                        >
                          <Field name="tipo_vulnerabilidad" as="select">
                            <option >{'Dato no recolectado'} </option>
                            {this.state.tipoVulnerabilidades.map(x => (
                              <option value={x.id}>{x.nombre} </option>
                            ))}
                          </Field>
                        </FS>
                      </Row>
                    </div>
                  ) : (
                    <FS
                      label="¿Formas parte de algún grupo excluido o vulnerable?"
                      name="tipo_vulnerabilidad"
                      errors={errors}
                      col={[6]}

                    >
                      <Field name="tipo_vulnerabilidad" as="select">
                        <option >{'Dato no recolectado'} </option>
                        {this.state.tipoVulnerabilidades.map(x => (
                          <option value={x.id}>{x.nombre} </option>
                        ))}
                      </Field>
                    </FS>
                  )}
                  {this.state.isEditing && (
                    <>
                      <FS
                        label="Archivo(s) del participante"
                        name="file"
                        errors={errors}
                        col={[6]}
                      >
                        <input
                          id="file"
                          name="file"
                          type="file"
                          accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
                          onChange={async (event) => {
                            const reader = new FileReader();

                            reader.onload = () => {
                              setFieldValue('file', reader.result);
                              const API = Utils.API;
                              const payload = new FormData();

                              const fileName = event.target.files[0]?.name;
                              fetch(reader.result)
                                .then(res => res.blob())
                                .then(blob => {
                                  const file = new File([blob], blob.name);
                                  payload.append('participante', beneficiario_id);
                                  payload.append('documento', file);
                                  payload.append('descripcion', fileName);
                                  req.post(API + 'participantes/documentos/store', payload)
                                    .then(() => {
                                      this.fetchFiles();
                                    });

                                })
                            };
                            const file = event.currentTarget.files[0];
                            reader.readAsDataURL(file);
                          }}
                        />
                      </FS>

                      {this.props.isOnline && <FS
                        label="Archivos"
                        name='archivos'
                        errors={errors}
                        col={[6]}

                      >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {(this.state.files || []).map(x => (
                            <div style={{ marginBlock: 5 }} >
                              <a href={x.url} target={'_blank'} rel='noopener'>

                                {x.descripcion}
                              </a>
                              <RemoveCircleOutlineIcon
                                style={{ marginLeft: 10, cursor: 'pointer' }}
                                color='error'
                                onClick={() => {

                                  req.delete(`${API_URL}participantes/documentos/${x.id}/delete`, { params: { participante: beneficiario_id } })
                                    .then(() => {
                                      this.fetchFiles().then(() => {
                                        setFieldValue('file', null);
                                      });
                                    });
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </FS>}
                    </>
                  )}
                  <button type="submit" className="buttonSubmit">{isEditing ? "Actualizar" : "Registrar"}</button>
                </form>
              )}
            </Formik>
            {!this.props.publicPollType && <Modal
              size="md"
              centered
              show={popupValidateRegister}
              onHide={this.hide_PopUpValidateRegister}
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <p style={{ textAlign: 'center' }}>¡El beneficiario <strong>{`${responseFormSubmited.nombres} ${responseFormSubmited.apellidos} ID: ${this.state.id}`}</strong> fue creado satisfactoriamente!</p>
                {responseFormSubmited.tipo?.id !== 1 ?
                  <>
                    <p style={{ textAlign: 'center' }}>¿Desea completarle un formulario?</p>
                    {indirectForms.map((form) => (
                      <div style={{ textAlign: 'center' }} key={form.id} className='d-block'>
                        <Link to={`/formularios/${form.id}/${responseFormSubmited.id}`}>
                          {`${form.nombre}`}
                        </Link>
                      </div>
                    ))}
                  </>
                  :
                  <p style={{ textAlign: 'center' }}>¿Desea completarle la <strong>{entryform.nombre}</strong>?</p>
                }
              </Modal.Body>
              {
                responseFormSubmited.tipo?.id === 1 ?
                  <Modal.Footer>
                    <button onClick={this.hide_PopUpValidateRegister}  >No</button>
                    {this.state.encuestaInscripcion.popup ? (
                      <button onClick={() => this.setState({ openPop: true })}>Si</button>
                    ) : (
                      <button onClick={(e) => { this.redirectToForm(entryform) }}  >Si</button>
                    )}
                  </Modal.Footer>
                  :
                  <Modal.Footer>
                    <button onClick={() => this.props.history.push("/beneficiarios")}>No</button>
                  </Modal.Footer>
              }
            </Modal>}
            <Modal
              size="md"
              centered
              show={popupErrorRegister}
              onHide={this.hide_PopUpErrorRegister}
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <p style={{ textAlign: 'center' }}>El/la beneficiario/a "No" creado. Existe al menos un beneficiario con datos similares en el nombre, apellido, sexo, fecha de nacimiento y país de nacimiento.</p>
                <p style={{ textAlign: 'center' }}>El participante es: <strong className='text-danger text-uppercase'>{`${dataToPopupError.nombres} ${dataToPopupError.apellidos} (ID: ${dataToPopupError.codigo})`}</strong></p>
              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => this.props.history.push("/beneficiarios")}>Ok</button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.openPop}
              onHide={() => this.props.history.push("/beneficiarios")}
              size="lg"
              centered
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: "center", width: "100%" }}>
                  {this.state.encuestaInscripcion.popup_titulo}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
                <p>{this.state.encuestaInscripcion.popup_cuerpo}</p>
              </Modal.Body>
              <Modal.Footer>
                <div className='d-flex justify-content-center'>
                  <button
                    type='button'
                    className='btn rounded-pill me-2'
                    style={{ background: "red" }}
                    onClick={<button onClick={() => this.props.history.push("/beneficiarios")}>Cancelar</button>}
                  >
                    Cancelar
                  </button>
                  <button onClick={(e) => { this.redirectToForm(entryform) }}>Continuar</button>
                </div>
              </Modal.Footer>
            </Modal>

          </div>
        </CSSTransition>
      </Container>
    );
  }
}
export default withRouter(Wrapper);