import {
    CheckCircleOutlined,
    DoNotDisturbOnOutlined,
    ModeEditOutlined,
} from '@mui/icons-material';
import MaterialTable from 'material-table';
import { createRef, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { changeDateFormat, checkStatus } from '../components/functions';
import Audits from '../components/Audits';
import HistoryIcon from '@mui/icons-material/History';
import { req } from '../utils/request';
import { API_URL } from '../utils/config';

function LinkList(props) {
    const [organizaciones, setOrganizaciones] = useState([]);
    const [formularios, setFormularios] = useState([]);
    const [auditModal, setAuditModal] = useState(null);
    const [alert, setAlert] = useState({
        show: false,
        type: 'success',
        message: '',
    });
    const [alertTimeout, setAlertTimeout] = useState();

    const tableRef = createRef();

    useEffect(() => {
        req.get(`${API_URL}selectores/organizaciones`).then((response) => setOrganizaciones(response.data.data ?? []));
        req.get(`${API_URL}selectores/formularios`).then((response) => setFormularios(response.data.data ?? []));
    }, []);

    const fetchData = (query) => {
        return new Promise((resolve) => {
            const params = { page: query.page + 1, paginacion: query.pageSize };
            // TODO implement filter
            if (query.search) {
                params.busqueda = query.search;
            }

            req.get(`${API_URL}enlaces`, { params }).then((response) => {
                resolve({
                    data: (response.data.data ?? []).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
            });
        });
    };

    const toggle = (id) => {
        clearTimeout(alertTimeout);
        req.post(`${API_URL}enlaces/${id}/toggle`)
            .then((response) => {
                tableRef.current.onQueryChange({});
                const activo = response.data.data.activo;
                setAlert({
                    show: true,
                    type: 'success',
                    message: `El enlace ha sido ${activo ? 'activada' : 'desactivada'
                        } con éxito`,
                });
            })
            .finally(() => {
                const timeoutId = setTimeout(() => {
                    setAlert({ show: false, type: 'success', message: '' });
                }, 5000);
                setAlertTimeout(timeoutId);
            });
    };

    return (
        <div className='container'>
            {!!auditModal && (
                <Audits
                    close={() => setAuditModal(null)}
                    show={!!auditModal}
                    tipo={'enlaces'}
                    id={auditModal}
                />
            )}
            <div className='titleButtons'>
                <div className='justify-content-end nav'>
                    {/* Alerts */}
                    <div className={`alert ${alert.show ? 'show' : ''} ${alert.type}`}>
                        <p>{alert.message}</p>
                    </div>
                    {/* Create activity button */}
                    <Link to='/enlaces/nueva' className='nav-item'>
                        <span className='nav-link'>Crear Enlace</span>
                    </Link>
                </div>
            </div>
            <div className='mainBox'>
                <MaterialTable
                    title=''
                    tableRef={tableRef}
                    columns={[
                        {
                            title: 'ID',
                            defaultSort: 'desc',
                            field: 'id',
                        },
                        {
                            title: 'Código',
                            defaultSort: 'desc',
                            field: 'codigo',
                        },
                        {
                            title: 'Url',
                            field: 'url',
                            render: rowData => {
                                const url = `${window.location.origin}/enlaces/${rowData.anonimo ? 'anonimas' : 'participantes'}/${rowData.codigo}`;
                                return <a target="_blank" rel="noopener noreferrer" href={url}>{url}</a>
                            }
                        },
                        {
                            title: 'Descripción',
                            field: 'descripcion',
                        },
                        {
                            title: 'Tipo',
                            field: 'anonimo',
                            render: (rowData) => rowData.anonimo ? 'Anónimo' : 'No anónimo'
                        },
                        {
                            title: 'Organización',
                            field: 'organizacion_id',
                            render: rowData => organizaciones.find(organizacion => parseInt(organizacion.id) === parseInt(rowData.organizacion_id))?.nombre
                        },
                        {
                            title: 'Formulario',
                            field: 'formulario_id',
                            render: rowData => formularios.find(form => parseInt(form.id) === parseInt(rowData.formulario_id))?.nombre
                        },
                        {
                            title: 'Límite',
                            field: 'limite',
                            render: (rowData) => rowData.limite ? rowData.limite : 'No'
                        },
                        {
                            title: 'Encuestas Finalizadas',
                            field: 'encuestas_finalizadas',
                            render: (rowData) => rowData?.encuestas_finalizadas || 0
                        },
                        {
                            title: 'Expira',
                            field: 'Expira',
                            render: (rowData) => rowData.expira ? changeDateFormat(rowData.expira) : 'No',
                        },
                        {
                            title: 'Estatus',
                            field: 'activo',
                            render: (rowData) => checkStatus(rowData.activo),
                        },
                    ]}
                    data={fetchData}
                    actions={[
                        {
                            icon: ModeEditOutlined,
                            tooltip: 'Editar',
                            onClick: (event, rowData) =>
                                props.history.push(`/enlaces/${rowData.id}`),
                        },
                        (rowData) => ({
                            icon: CheckCircleOutlined,
                            tooltip: 'Activar',
                            onClick: (event, rowData) => toggle(rowData.id),
                            disabled: rowData.activo,
                        }),
                        (rowData) => ({
                            icon: DoNotDisturbOnOutlined,
                            tooltip: 'Desactivar',
                            onClick: (event, rowData) => toggle(rowData.id),
                            disabled: !rowData.activo,
                        }),
                        () => ({
                            icon: HistoryIcon,
                            tooltip: 'Auditoria',
                            onClick: (event, rowData) => setAuditModal(rowData.id)
                        })
                    ]}
                    localization={{
                        header: {
                            actions: 'Acciones',
                        },
                        toolbar: {
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar',
                        },
                        pagination: {
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Página siguiente',
                            firstTooltip: 'Primera página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay registros que mostrar',
                        },
                    }}
                    options={{
                        search: true,
                        selection: false,
                        searchFieldAlignment: 'right',
                        actionsColumnIndex: -1,
                        paging: true,
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        draggable: false,
                    }}
                />
            </div>
        </div>
    );
}

export default withRouter(LinkList);